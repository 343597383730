<template>
  <v-card>
    <v-card-text>
      <v-row v-if="item">
        <v-col> <b>Cod Fornecedor: </b> {{ item.providerCode }} </v-col>
        <v-col> <b>Nome: </b> {{ item.name }} </v-col>
        <!-- <v-col> <b>Quantidade: </b> {{ item.amount }} </v-col> -->
        <!-- <v-col> {{ item }} </v-col> -->
        <v-col>

        <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-progress-circular
                      :rotate="90"
                      :size="70"
                      :width="10"
                      :value="percent(item)"
                      :color="colorProgress(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ percent(item) }} %
                    </v-progress-circular>
                  </template>
                  <div>Nome: {{ item.name }}</div>
                  <div>Inicial: {{ item.amountStart }} {{item.unity}}</div>
                  <div>Carregado: {{ item.amountStart - item.amount }} {{item.unity}}</div>
                  <div>Saldo: {{ item.amount }} {{item.unity}}</div>
                </v-tooltip>
        </v-col>
        <v-col> <b>Unidade: </b> {{ item.unity }} </v-col>
      </v-row>
      <v-row v-show="enableSend">
        <v-col>
          <v-btn block @click="emit">
            <v-icon> mdi-arrow-right-top </v-icon>
            Enviar para um Motorista
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "OrdemItems",
  props: ["item", "enableSend"],
  methods: {
    emit() {
      let it = { ...this.item, itemId: this.item.id };
      delete it.id;
      this.$emit("itemToSend", it);
    },
    colorProgress(item) {
      let pct = this.percent(item);
      if (pct > 0 && pct < 50) {
        return "orange";
      } else if (pct > 0 && pct < 20) {
        return "red";
      } else {
        return "green";
      }
    },
    percent(item) {
      let pct = (item.amount / item.amountStart) * 100;
      return pct > 0 ? pct.toFixed(1) : 0;
    },
  },
};
</script>

<style>
</style>