<template>
  <div>
    <div class="mapa">
      <v-btn color="success" z-index="5" fab dark small absolute left>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="fullScreen" class="m-1" v-on="on" v-bind="attrs">
              mdi-image-filter-center-focus-strong
            </v-icon>
          </template>
          <span>Mapa tela cheia</span>
        </v-tooltip>
      </v-btn>
      <div
        id="mapContainer"
        ref="ctn"
        class="d-flex align-self-stretch"
        :style="`height: ${alt}px; z-index:3`"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Icon } from "leaflet";

export default {
  name: "MapaDashBoard",
  props: {
    alt: {
      type: Number,
      default: 580,
    },
  },
  components: {},
  data() {
    return {
      map: null,
      lastMarker: null,
      cercas: null,
      click: false,
      cords: [],
      draw: null,
      constructs: [],
      makers: [],
      lines: [],
      resume: [],
      constructionIcon: new Icon({
        iconUrl: require("@/assets/cone.png"),
        iconSize: [60, 60],
      }),
      coords:[],
    };
  },
  computed: {
    ...mapGetters([
      "getConstructions",
      "getConstructionSelect",
      "getConstructionNameById",
      "getConstructionById",
      "getVehiclesNameById",
      "getVehicleById",
      "getDriverNameById",
      "getConstruction",
      "getDrivers",
      "getVehicles",
      "getOrdemsSelect",
      "getOrdemsStarted",
      "getBOAvailable",
      "getProviderById",
      "getUserNameById",
      "getOrderItems",
    ]),
  },
  methods: {
    async showAllActive() {
      // let cords = [];

      // limpando os elementos que estão sobre o mapa
      this.constructs.forEach((e) => {
        e.remove(this.map);
      });
      this.makers.forEach((e) => {
        e.remove(this.map);
      });
      this.lines.forEach((e) => {
        e.remove(this.map);
      });

      let act = this.getBOAvailable;
      // separa os ids das obras
      // let constructionsIds = [
      //   ...new Set(act.map((e) => e.order.constructionId)),
      // ];
      this.loadReport();
      // aficiona os marcadores e linhas
      act.forEach((e) => {
        // obra
        let obra = this.getConstructionById(e.order.constructionId);

        let fornecedor = this.getProviderById(e.order.providerId);
        if (!e.position) return;
        // poligono da obra
        // let a = L.polygon(obra.coordinates, { color: obra.color })
        //   .addTo(this.map);

        // marcador do veiculo
        let b = L.marker([e.position.latitude, e.position.longitude])
          .bindTooltip(
            `
            <table>
                <tr>
                    <td>Placa:</td>
                    <td style="text-align: center;">${
                      e.plate ? e.plate.toUpperCase() : "NÃO INFORMADO"
                    }</td>
                </tr>
                <tr>
                    <td>Ordem de Compra:</td>
                    <td style="text-align: center;">
                      ${e.order.id} ${e.order.erpIdentifier}
                    </td>
                </tr>
                <tr>
                    <td>Inicio:</td>
                    <td style="text-align: center;">
                      ${new Date(e.loadedDate).toLocaleString()}
                    </td>
                </tr>
                <tr>
                    <td>Motorista:</td>
                    <td style="text-align: center;">
                      ${this.getUserNameById(e.userId)}
                    </td>
                </tr>
                <tr>
                    <td>Endereço:</td>
                    <td style="text-align: center;">
                      ${e.position.address}
                    </td>
                </tr>
                <tr>
                    <td>Velocidade:</td>
                    <td style="text-align: center;">
                      ${(e.position.speed * 1.825).toFixed(1)} Km/h
                    </td>
                </tr>
                <tr>
                    <td>Última Posição:</td>
                    <td style="text-align: center;">
                      ${new Date(e.position.fixTime).toLocaleString()}
                    </td>
                </tr>
                <tr>
                    <td>Fornecedor:</td>
                    <td style="text-align: center;">
                      ${fornecedor.name}
                    </td>
                </tr>
                <tr>
                    <td>Obra:</td>
                    <td style="text-align: center;">
                      ${obra.name}
                    </td>
                </tr>
                <tr>
                    <td>Material:</td>
                    <td style="text-align: center;">
                      ${e.items[0].name}
                    </td>
                </tr>
                <tr>
                    <td>Quantidade Coletada:</td>
                    <td style="text-align: center;">
                      ${e.colectedAmount}
                    </td>
                </tr>
            </table>
            `
          )
          .addTo(this.map);

        // linha entre a obra e o veiculo
        let c = L.polyline(
          [
            obra.coordinateCenter,
            [e.position.latitude, e.position.longitude],
            fornecedor.coordinateCenter,
          ],
          {
            color: obra.color,
          }
        ).addTo(this.map);
        // poligono do fornecedor
        let d = L.polygon(fornecedor.coordinates, { color: fornecedor.color })
          .bindTooltip(fornecedor.name)
          .addTo(this.map);

        // adicionando as cordenadas para centralizar o mapa
        // cords.push(obra.coordinates);
        // cords.push([e.position.latitude, e.position.longitude]);
        // cords.push(fornecedor.coordinates);
        // this.coords.push(obra.coordinates);
        this.coords.push([e.position.latitude, e.position.longitude]);
        this.coords.push(fornecedor.coordinates);

        // insiro os elementos nestes arrays para poder apaga-los em uma nova entrada
        // this.constructs.push(a);
        this.constructs.push(d);
        this.makers.push(b);
        this.lines.push(c);
      });
      // if (cords.length) {
      //   this.map.fitBounds(cords);
      // }
    },
    fullScreen() {
      let elem = document.getElementById("mapContainer");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    // logica para gerar o resumo
    genResume(report) {
      let constructionsIds = [
        ...new Set(report.map((e) => e.order.constructionId)),
      ];
      let data = [];
      // separando por obras
      constructionsIds.forEach((e) => {
        data.push({
          constructionId: e,
          constructionName: this.getConstructionNameById(e),
          orders: report.filter((f) => f.order.constructionId == e),
          sended: report.filter(
            (f) => f.order.constructionId == e && f.createUserId != "0"
          ).length,
          loaded: report.filter(
            (f) => f.order.constructionId == e && f.loadedDate
          ).length,
          unloaded: report.filter(
            (f) => f.order.constructionId == e && f.finishedDate
          ).length,
        });
      });
      // separando por items
      data.forEach((e) => {
        let itemsIds = [...new Set(e.orders.map((e) => e.itemId))];
        let items = [];
        itemsIds.forEach((f) => {
          items.push({
            itemId: f,
            itemName: this.getItemName(f),
            orders: e.orders.filter((g) => g.itemId == f),
            loaded: e.orders.filter((g) => g.itemId == f && g.loadedDate)
              .length,
            unloaded: e.orders.filter((g) => g.itemId == f && g.finishedDate)
              .length,
            quantityLoaded: e.orders
              .filter((g) => g.itemId == f && g.loadedDate)
              .reduce((a, b) => a + b.colectedAmount, 0),
            quantityUnloaded: e.orders
              .filter((g) => g.itemId == f && g.finishedDate)
              .reduce((a, b) => a + b.colectedAmount, 0),
          });
        });
        e.items = items;
      });
      // console.log(data);
      this.genHtmlPopUp(data);
      return data;
    },
    // pega o nome do item
    getItemName(itemId) {
      let item = this.getOrderItems.find((e) => e.id == itemId);
      return item ? `${item.name} - ${item.unity}(s)` : "";
    },
    // gera o html para o popup
    genHtmlPopUp(data) {
      let style = "display: flex; justify-content: space-between; ";
      let styleCenter =
        "display: flex; justify-content: center; background-color: #f5f5f5;";
      data.forEach((c) => {
        c.html = `<div style="${styleCenter}"><b>${c.constructionName}</b></div>
                  <div style="${style}">
                    <b>Cargas:</b>
                    <b>${c.loaded}</b>
                  </div>
                  <div style="${style}">
                    <b>Descargas:</b>
                    <b>${c.unloaded}</b>
                  </div>
                  `;
        // subItems
        c.items.forEach((i) => {
          c.html += `<div style="${styleCenter}">
                        <b>${i.itemName}</b>
                      </div>
                      <div style="${style}">
                        <i> Solicitado:</i>
                        <i>${this.getSendedAmount(i.orders)}</i>
                      </div>
                      <div style="${style}">
                        <i> Saldo:</i>
                        <i>${(
                          this.getSendedAmount(i.orders) - i.quantityLoaded || 0
                        ).toFixed(2)}</i>
                      </div>
                      <div style="${style}">
                        <b>Carregado:</b>
                        <b>${
                          i.quantityLoaded ? i.quantityLoaded.toFixed(2) : 0
                        }</b>
                      </div>
                      <div style="${style}">
                        <b>Descarregado:</b>
                        <b>${
                          i.quantityUnloaded ? i.quantityUnloaded.toFixed(2) : 0
                        }</b>
                      </div>`;
        });
      });
    },
    // pega o total de itens enviados
    getSendedAmount(list) {
      let amounts = list.map((e) =>
        e.createUserId != "0" ? parseFloat(e.amount) : 0
      );
      let value = amounts.reduce(function (a, b) {
        return a + b;
      });
      console.log();
      return Number(value).toFixed(2);
    },
    // busca o resumo do dia
    loadReport(constructions) {
      let rep = {
        loadS: `${new Date().toISOString().split("T")[0]} 00:00:00`,
        loadE: `${new Date().toISOString().split("T")[0]} 23:59:59`,
        constructions,
        cb: (response) => {
          if (response.status == 200) {
            // console.log(response.data);
            this.generateTooltips(response.data);
          }
        },
      };
      this.$store.dispatch("getReportBOtoUser", rep);
    },
    // gera os tooltips para  resumo do mapa
    generateTooltips(report) {
      // let coordinates = [];
      this.resume = this.genResume(report);
      this.resume.forEach((r) => {
        let obra = this.getConstructionById(r.constructionId);
        // tooltip
        // let a = L.tooltip({
        //   // alterna esquerda e direita para não ficar sobreposto
        //   direction: i % 2 == 0 ? "right" : "left",
        //   permanent: true,
        // })
        //   .setLatLng(L.latLng(obra.coordinates[0], obra.coordinates[1]))
        //   .setContent(r.html || obra.name)
        //   .addTo(this.map);
        // poligono
        // let a = L.polygon(obra.coordinates, { color: obra.color })
        //   .bindTooltip(r.html || obra.name,{
        //   // alterna esquerda e direita para não ficar sobreposto
        //   direction: i % 2 == 0 ? "right" : "left",
        // })
        //   .addTo(this.map);
        let a = L.marker(L.latLng(obra.coordinates[0], obra.coordinates[1]), {
          icon: this.constructionIcon,
        })
          .bindTooltip(r.html || obra.name)
          // .bindPopup(r.html || obra.name)
          .addTo(this.map);
        // insere no array de tooltips para serem removidos na atualização
        this.constructs.push(a);
        // coordinates.push(obra.coordinates);
        this.coords.push(obra.coordinates);
      });
      if (this.coords.length > 0) {
        this.map.fitBounds(this.coords);
      }
    },
  },
  watch: {
    getBOAvailable: {
      async handler(val) {
        if (this.mapLoaded) {
          this.showAllActive(val);
        } else {
          setTimeout(await this.showAllActive(), 200);
        }
      },
    },
  },
  mounted() {
    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
    this.map = L.map("mapContainer").setView(
      [-14.337566608887226, -54.93164062500001],
      4
    );
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=" +
        this.$store.getters.getMapBoxKey,
      {
        maxZoom: 18,
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
          '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
          'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        id: "mapbox/streets-v11",
        tileSize: 512,
        zoomOffset: -1,
      }
    ).addTo(this.map);
    // let VUE = this;
    // this.map.whenReady(function () {
    //   VUE.showAllActive();
    // });
  },
};
</script>

<style scoped>
#mapContainer {
  width: 100%;
}
.centerTD {
  text-align: center;
  vertical-align: middle;
}
</style>