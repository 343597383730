<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title> Simulador </v-card-title>
          <v-card-text>
            <div class="mapa">
              <div
                id="mapContainer"
                ref="ctn"
                class="d-flex align-self-stretch"
                :style="`height: ${alt}px; z-index:3`"
              ></div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-card>
          <v-card-title>
            Dados para enviar
          </v-card-title>
          <v-row>
            <v-col>
              <v-autocomplete
                dense
                filled
                label="Ordem de Serviço"
                :items="getOrdemsSelect"
                v-model="ocState.number"
                @change="setOc"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                dense
                filled
                label="Motorista"
                :items="getDrivers"
                v-model="ocState.driverId"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-autocomplete
            dense
            filled
            label="Veiculo"
            :items="getVehicles"
            v-model="ocState.vehicleId"
          ></v-autocomplete>

          <v-autocomplete
            dense
            filled
            label="Obra"
            @change="showObra"
            :items="getConstructionSelect"
            v-model="ocState.obraId"
            :disabled="ocState.number != undefined"
          ></v-autocomplete>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-left">OC</th>
              <th class="text-left">Obra</th>
              <th class="text-left">Iniciada</th>
              <th class="text-left">Veículo</th>
              <th class="text-left">Motorista</th>
              <th class="text-left">Cordenada</th>
              <th class="text-left">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in getOrdemsStarted" :key="item.name">
              <td>{{ item.number }}</td>
              <td>{{ getConstructionNameById(item.obraId) }}</td>
              <td>{{ item.start }}</td>
              <td>{{ getVehiclesNameById(item.vehicleId) }}</td>
              <td>{{ getDriverNameById(item.driverId) }}</td>
              <td>{{ item.cord }}</td>
              <td>
                <v-icon small @click="clear(item)" class="m-1">
                  mdi-trash-can
                </v-icon>
                <v-icon small @click="edt(item)"> mdi-lead-pencil </v-icon>
                <v-icon small @click="view(item)"> mdi-eye </v-icon>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Icon } from "leaflet";

export default {
  name: "Simulador",
  props: {
    alt: {
      type: Number,
      default: 380,
    },
  },
  components: {},
  data() {
    return {
      map: null,
      lastMarker: null,
      lastLine: null,
      cercas: null,
      click: false,
      cords: [],
      draw: null,
      constructs: [],
      obra: 0,
      motorista: 0,
      veiculo: 0,
      ocState: {},
    };
  },
  computed: {
    ...mapGetters([
      "getConstructions",
      "getConstructionSelect",
      "getConstructionNameById",
      "getConstructionById",
      "getVehiclesNameById",
      "getDriverNameById",
      "getConstruction",
      "getDrivers",
      "getVehicles",
      "getOrdemsSelect",
      "getOrdemsStarted",
    ]),
  },
  methods: {
    clickMap(e) {
      console.log(this.ocState);
      if (
        !this.ocState.driverId ||
        !this.ocState.vehicleId ||
        !this.ocState.obraId ||
        !this.ocState.number == undefined
      ) {
        alert("Selecione motorista, veiculo, ordem e obra");
        return;
      }

      if (this.lastMarker) {
        this.lastMarker.remove(this.map);
      }
      this.lastMarker = L.marker([e.latlng.lat, e.latlng.lng]).addTo(this.map);

      if (this.lastLine) {
        this.lastLine.remove(this.map);
      }
      let obra = this.$store.getters.getConstructionById(this.ocState.obraId)
      this.lastLine = L.polyline([obra.cords[0], [e.latlng.lat, e.latlng.lng]], {color: obra.color}).addTo(this.map);


      let os = this.$store.getters.getOrdemById(this.ocState.number);
      if (os) {
        os.driverId = this.ocState.driverId;
        os.vehicleId = this.ocState.vehicleId;
        os.start = new Date().toLocaleString();
        os.cord = [e.latlng.lat, e.latlng.lng];
        this.$store.dispatch("edtOrdem", os);
      }
    },
    clearMap() {
      this.cords = [];
      this.draw.remove(this.map);
    },
    setOc() {
      // this.cords = [];
      // this.draw.remove(this.map);
      let os = this.$store.getters.getOrdemById(this.ocState.number);
      this.ocState.obraId = os.obraId
      this.showObra()
    },
    showObra() {
      let obra = this.$store.getters.getConstructionById(this.ocState.obraId)
      if (this.draw) {
        this.draw.remove();
      }
      if (obra) {
        this.draw = L.polygon(obra.cords, { color: obra.color })
          .bindTooltip(obra.name, {
            permanent: true,
            direction: "center",
            opacity: 0.8,
          })
          .addTo(this.map);
        this.map.fitBounds(obra.cords);
      }
    },
    view(){

    },
    edt(item){
      console.log(item);
      this.ocState = item
    },
    clear(item){
      this.$store.dispatch('clearConstructions', item)
    },
    showAllConstruction() {
      let cords = [];
      this.getConstructions.forEach((element) => {
        let a = L.polygon(element.cords, { color: element.color })
          .bindTooltip(element.name, {
            permanent: true,
            direction: "center",
            opacity: 0.8,
          })
          .addTo(this.map);
        cords.push(element.cords);
        this.constructs.push(a);
      });
      this.map.fitBounds(cords);
    },
  },
  mounted() {
    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
    this.map = L.map("mapContainer").setView(
      [-14.337566608887226, -54.93164062500001],
      4
    );
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=" + this.$store.getters.getMapBoxKey,
      {
        maxZoom: 18,
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
          '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
          'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        id: "mapbox/streets-v11",
        tileSize: 512,
        zoomOffset: -1,
      }
    ).addTo(this.map);
    this.map.on("click", this.clickMap);
  },
};
</script>

<style scoped>
#mapContainer {
  width: 100%;
}
</style>
