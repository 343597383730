<template>
  <v-app-bar  dark app>
    <v-app-bar-nav-icon  @click="$store.dispatch('toogleDrawer')" v-show="getLogged"></v-app-bar-nav-icon>

    <!-- @mouseenter="$store.dispatch('toogleDrawer')" -->

    <v-toolbar-title>Cargas STV</v-toolbar-title>

     <v-spacer></v-spacer>

    <v-menu offset-y >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar class="ma-2" size="45" v-bind="attrs" v-on="on" v-show="getLogged">
          <img :src="getUsuario.picture" :alt="getUsuario.name" />
        </v-avatar>
        <span>
          {{ getUsuario.name }}
        </span>
      </template>
      <v-list>
        <v-list-item key="Trocar Senha" to="/myuser" >
          <v-list-item-title
            ><v-icon>mdi-key-variant</v-icon> Editar Usuário</v-list-item-title
          >
        </v-list-item>
        <v-list-item key="sair" @click="logoff" >
          <v-list-item-title 
            ><v-icon>mdi-logout-variant</v-icon> Sair</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>

  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AppBar",
  methods: {
    logoff(){
      this.$store.dispatch('logOff')
    }
  },
  computed: {
    ...mapGetters(["getUsuario", "getLogged"]),
  },

};
</script>

<style>
</style>