export default {
    state: {
        dialogRelatorio: {
            show:false,
            data: {}
        },
        dialogRelatorioHistory: {
            show:false,
            data: {}
        },
    },
    mutations: {
        dialogRelatorioOpen(state, payload) {
            state.dialogRelatorio.data = payload
            state.dialogRelatorio.show = true
        },
        dialogRelatorioClose(state) {
            state.dialogRelatorio.data = {}
            state.dialogRelatorio.show = false
        },
        dialogRelatorioHistoryOpen(state, payload) {
            state.dialogRelatorioHistory.data = payload
            state.dialogRelatorioHistory.show = true
        },
        dialogRelatorioHistoryClose(state) {
            state.dialogRelatorioHistory.data = {}
            state.dialogRelatorioHistory.show = false
        },
    },
    actions: {
        dialogRelatorioOpen(context, payload) {
            context.commit('dialogRelatorioOpen', payload)
        },
        dialogRelatorioClose(context) {
            context.commit('dialogRelatorioClose')
        },
        dialogRelatorioHistoryOpen(context, payload) {
            context.commit('dialogRelatorioHistoryOpen', payload)
        },
        dialogRelatorioHistoryClose(context) {
            context.commit('dialogRelatorioHistoryClose')
        },
    },
    getters: {
        getDialogRelatorio(state) {
            return state.dialogRelatorio
        },
        getDialogRelatorioState(state) {
            return state.dialogRelatorio.show
        },
        getDialogRelatorioHistory(state) {
            return state.dialogRelatorioHistory
        },
        getDialogRelatorioHistoryState(state) {
            return state.dialogRelatorioHistory.show
        },
    }
}