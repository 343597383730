<template>
  <v-card>
    <v-card-title> Usuários </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              {{ user.id ? "Editar" : "Novo" }} Usuário
              <v-spacer></v-spacer>
              <v-btn @click="showForm = !showForm" color="warning"
                >{{ showForm ? "Esconder" : "Mostrar" }} Formulario</v-btn
              >
            </v-card-title>
            <v-card-text v-show="showForm">
              <v-row>
                <v-col>
                  <v-text-field
                    label="Nome do Usuário"
                    hint="Nome do Usuário"
                    persistent-hint
                    outlined
                    v-model="user.name"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-textarea
                    outlined
                    rows="1"
                    label="Descrição / Observações"
                    v-model="user.description"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Login"
                    hint="Login do Usuário"
                    persistent-hint
                    outlined
                    v-model="user.userName"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Senha"
                    hint="Senha do Usuário"
                    persistent-hint
                    outlined
                    v-model="user.password"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    outlined
                    hint="Tipo do Usuário"
                    persistent-hint
                    label="Tipo"
                    :items="getTypesSelect"
                    v-model="user.type"
                  >
                  </v-select>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="user.traceable"
                    :label="`Rastreável pelo Aplicativo`"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="warning" @click="clearForm">Cancelar</v-btn>
                <v-btn class="mx-2" color="success" @click="save">Salvar</v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Nome</th>
                <th class="text-left">Usuário</th>
                <th class="text-left">Tipo</th>
                <th class="text-left">Descrição</th>
                <th class="text-left">Rastreavel</th>
                <th class="text-left">Criado</th>
                <th class="text-left">Rastreável</th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in getUsers" :key="index">
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.userName }}</td>
                <td>{{ `${item.type} - ${item.acessoName}` }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.traceable ? "SIM" : "NÃO" }}</td>
                <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
                <td>{{ new Date(item.updatedAt).toLocaleString() }}</td>
                <td>
                  <v-icon small @click="del(item)" class="m-1">
                    mdi-trash-can
                  </v-icon>
                  <v-icon small @click="edt(item)"> mdi-lead-pencil </v-icon>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "motoristas",
  computed: {
    ...mapGetters(["getUsers", "getTypesSelect"]),
  },
  methods: {
    save() {
      this.$store.dispatch("saveUser", this.user);
    },
    clearForm() {
      this.user = {};
    },
    del(e) {
      this.$store.dispatch("deleteUser", e);
    },
    edt(e) {
      this.user = e;
      this.showForm = true;
    },
  },
  data: () => ({
    showForm: false,
    user: { traceable: false },
  }),
  created() {
    this.$store.dispatch("loadUsers");
    this.$store.dispatch("loadUsersTypes");
  },
};
</script>

<style>
</style>