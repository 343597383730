<template>
  <v-app>
    <Menu /> 
    <AppBar />
    <v-main>
      <v-container fluid>
        <router-view></router-view>
        <v-snackbar
          v-model="snck.snackbar"
          :color="snck.color"
          :timeout="snck.timeout"
        >
          {{ snck.text }}
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Menu from "./components/Template/MenuBanco";
// import Menu from './components/template/Menu.vue'
import AppBar from "@/components/Template/AppBar.vue";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Menu,
    AppBar,
  },

  data: () => ({
    snck: {},
  }),
  computed: { ...mapGetters(["snackbar"]) },
  watch: {
    snackbar() {
      this.snck = this.snackbar;
    },
  },
  created() {
    this.$store.dispatch('setStartNavigation',window.location.pathname)
  },
};
</script>
