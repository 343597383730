import axios from 'axios'
export default {
    state: {
        BOtoUser: [],
        BOLoaded: [],
        BOAvailable: [],
        BOReport: [],
        BOReportFilter: {
            finishE: "",
            finishS: "",
            loadS: "",
            loadE: "",
            createS: "",
            createE: "",
        },
        orderFilterBO:{
            orders: [],
            users: [],
        }
    },
    mutations: {
        setBOtoUser(state, payload) {
            state.BOtoUser = payload
        },
        setOrderFilterBO(state, payload) {
            state.orderFilterBO = payload
        },
        setBOLoaded(state, payload) {
            state.BOLoaded = payload
        },
        setBOAvailable(state, payload) {
            state.BOAvailable = payload
        },
        setBOReport(state, payload) {
            state.BOReport = payload
        },
        setBOReportFilter(state, payload) {
            state.BOReportFilter = payload
        },
    },
    actions: {
        setBOtoUser(context, payload) {
            context.commit('setBOtoUser', payload)
        },
        setOrderFilterBO(context, payload) {
            context.commit('setOrderFilterBO', payload)
        },
        setBOLoaded(context, payload) {
            context.commit('setBOLoaded', payload)
        },
        setBOAvailable(context, payload) {
            context.commit('setBOAvailable', payload)
        },
        setBOReport(context, payload) {
            context.commit('setBOReport', payload)
        },
        setBOReportFilter(context, payload) {
            context.commit('setBOReportFilter', payload)
            // localStorage.setItem("BOReportFilter", JSON.stringify(payload))
        },
        loadBOtoUser(context) {
            axios(`ocUsuario`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                });
                context.commit('setBOtoUser', resp.data)
            });
        },
        getReportBOtoUser(context, payload) {
            axios.post(`ocUsuario/report`, { ...payload }).then((resp) => {
                context.commit('setBOReport', resp.data);
                if(typeof payload.cb === "function"){
                    payload.cb(resp);
                }
            });
        },
        loadBOtoUserById(context, payload) {
            axios(`ocUsuario/byUser${payload ? '?userId=' + payload : ''}`).then((resp) => {
                context.commit('setBOtoUser', resp.data)
            });
        },
        LoadBOAvailable(context) {
            axios(`ocUsuario/available`).then((resp) => {
                context.commit('setBOAvailable', resp.data)
            });
        },
        loadBOLoadedtoUserById(context, payload) {
            axios(`ocUsuario/loaded${payload ? '?userId=' + payload : ''}`).then((resp) => {
                context.commit('setBOLoaded', resp.data)
            });
        },
        updateDistanceOc(context, payload) {
            axios(`ocUsuario/updateDistance/${payload}`).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Distância atualizada com Sucesso");
                }
            });
        },
        loadAllBOtoUsers(context) {
            axios(`ocUsuario/all`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                });
                context.commit('setBOtoUser', resp.data)
            });
        },
        saveBOtoUser(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/ocUsuario${finalUrl}`, payload).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Ordem para Usuário salva com Sucesso");
                    context.dispatch('loadBOtoUser')
                }
            });
        },
        deletBOtoUser(context, payload) {
            axios.delete(`/ocUsuario/${payload.id}`).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Ordem para Usuário Deletada com Sucesso");
                    context.dispatch('loadBOtoUser')
                }
            });
        },
    },
    getters: {
        getBOtoUsers(state) {
            return state.BOtoUser
        },
        getBOLoaded(state) {
            return state.BOLoaded
        },
        getBOAvailable(state) {
            return state.BOAvailable
        },
        getBOReport(state) {
            return state.BOReport
        },
        getBOReportFilter(state) {
            return state.BOReportFilter
            // if (state.BOReportFilter.length) {
            //     return state.BOReportFilter
            // } else {
            //     return JSON.parse(localStorage.getItem("BOReportFilter") || "{}")
            // }
        },
        getBOtoUserNameById: (state) => (id) => {
            if (!state.BOtoUser) {
                return id
            }
            let gp = state.BOtoUser.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
        getBOtoUserById: (state) => (id) => {
            if (!state.BOtoUser) {
                return id
            }
            let gp = state.BOtoUser.find(g =>
                g.id == id
            )
            return gp
        },
        getBOtoUserHeaders() {
            return [
                { text: "ID", value: "id" },
                { text: "Nome", value: "name" },
                { text: "Ordem Pai", value: "groupId" },
                { text: "Ordem Pai Nome", value: "groupName" },
                { text: "Descrição", value: "description" },
                { text: "Ativo", value: "ativo" },
                { text: "Ações", value: "actions", sortable: false },
            ]
        },
        getOrdersBOFiltred (state) {
            const filterModel = (arr = [], model = 0) => arr.length ? arr.includes(model) : true;
            let of = [...state.BOtoUser];
            // se vier um array de ids de usuarios, filtra por eles
            if (Array.isArray(state.orderFilterBO.users)) {
                of = of.filter((order) => filterModel(state.orderFilterBO.users, order.userId));
            }
            // se vier um array de ids de construções, filtra por eles
            if (Array.isArray(state.orderFilterBO.orders)) {
                of = of.filter((order) => filterModel(state.orderFilterBO.orders, parseInt(order.buyOrderId)));
            }
            return of;
        },
    },
}