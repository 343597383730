import axios from 'axios'
export default {
    state: {
        vehicles: [],
    },
    mutations: {
        setVehicles(state, payload) {
            state.vehicles = payload
        },
    },
    actions: {
        setVehicles(context, payload) {
            context.commit('setVehicles', payload)
        },
        loadVehicles(context) {
            axios(`veiculos`).then((resp) => {
                context.commit('setVehicles', resp.data)
            });
        },
    },
    getters: {
        getVehicles(state) {
            return state.vehicles
        },
        getVehiclesSelect(state) {
            let sel = []
            state.vehicles.forEach(e =>{
                sel.push({value: e.id, text:`${e.placa} - ${e.nome} - (${e.modelo})`})
            })
            return sel
        },
        getVehicleNameById: (state) => (id) => {
            if (!state.vehicles) {
                return id
            }
            let obj = state.vehicles.find(g =>
                g.id == id
            )
            return obj ? `${obj.placa} - ${obj.nome} - (${obj.modelo})` : ''
        },
        getVehicleById: (state) => (id) => {
            if (!state.vehicles) {
                return id
            }
            let obj = state.vehicles.find(g =>
                g.id == id
            )
            return obj
        },
    },
}