<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <Mapa @click="clickMap" />
        </v-col>
        <v-col fill-height>
          <v-card>
            <v-card-title> Lista dos Forncedores </v-card-title>
            <v-card-text>
              <v-simple-table height="500px">
                <thead>
                  <tr>
                    <th class="text-left">Cor</th>
                    <th class="text-left">Nome</th>
                    <th class="text-left">Descrição</th>
                    <th class="text-left">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in getProviders" :key="index">
                    <td>
                      <v-btn
                        :color="item.color"
                        fab
                        x-small
                        @click="$store.dispatch('setProvider', item)"
                      >
                        <v-icon dark> mdi-eye </v-icon>
                      </v-btn>
                    </td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.description }}</td>
                    <td>
                      <v-icon small @click="del(item)" class="m-2"> mdi-trash-can </v-icon>
                      <v-icon small @click="edt(item)" class="m-2">
                        mdi-lead-pencil
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <FormObra />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Mapa from "../components/Mapa/MapaForncedor";
import FormObra from "../components/Mapa/FormFornecedorMapa";
export default {
  name: "Obras",
  components: {
    Mapa,
    FormObra,
  },
  methods: {
    clickMap() {
      // this.$store.dispatch("setAppendNewDraw", e.latlng);
    },
    del(e){
      this.$store.dispatch("resetSnack");
      this.$store.dispatch("showWarningSnack", "Deletando...");
      this.$store.dispatch("deletProvider", e)
    },
    edt(e){
      // limpando o estado para alterar
      this.$store.dispatch('setEditionProvider', 0)
      this.$store.dispatch('setEditionProvider', e.id)
    },
    get(){
      this.$store.dispatch('loadProviders')
    }
  },
  computed: {
    ...mapGetters(["getProviders"]),
  },
  created(){
    this.get()
  }
};
</script>

<style>
</style>