import Vue from 'vue'
import store from '../store/index'
import VueRouter from 'vue-router'
import DashBoard from '../views/DashBoard.vue'
import Usuario from '../views/Usuario'
import Veiculo from '../views/Veiculo'
import Ordem from '../views/Ordem'
import Obra from '../views/Obra'
import Simulador from '../views/Simulador'
import Fornecedor from '../views/Fornecedor'
import OrdemUsuario from '../views/OrdemUsuario'
// import Simulador from '../components/Admin/AdminPage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/dashboard',
    name: 'DashBoard',
    component: DashBoard,
    meta: { requiresAuth: true }
  },
  {
    path: '/usuarios',
    name: 'Usuario',
    component: Usuario,
    meta: { requiresAuth: true }
  },
  {
    path: '/veiculo',
    name: 'Veiculo',
    component: Veiculo,
    meta: { requiresAuth: true }
  },
  {
    path: '/ordem',
    name: 'Ordem',
    component: Ordem,
    meta: { requiresAuth: true }
  },
  {
    path: '/fornecedor',
    name: 'Fornecedor',
    component: Fornecedor,
    meta: { requiresAuth: true }
  },
  {
    path: '/obra',
    name: 'Obra',
    component: Obra,
    meta: { requiresAuth: true }
  },
  {
    path: '/simulador',
    name: 'Simulador',
    component: Simulador,
    meta: { requiresAuth: true }
  },
  {
    path: '/ordemUsuario',
    name: 'OrdemUsuario',
    component: OrdemUsuario,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    // component: Login,
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/Logout'),
    meta: { requiresAuth: true }
  },
  {
    path: '/adm',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "adminsPages" */'../components/Admin/AdminPage'),
    meta: { requiresAuth: true }
  },
  {
    path: '/admforms',
    name: 'AdminForms',
    component: () => import(/* webpackChunkName: "adminsPages" */'../components/Admin/forms/AdminForms'),
    meta: { requiresAuth: true }
  },
  {
    path: '/request',
    name: 'Request',
    component: () => import(/* webpackChunkName: "adminsPages" */'../components/Admin/Request'),
    meta: { requiresAuth: true }
  },
  {
    path: '/nodeAdmin',
    name: 'NodeAdmin',
    component: () => import(/* webpackChunkName: "adminsPages" */'../components/Admin/NodeAdmin'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dispositivos',
    name: 'Dispositivos',
    component: () => import(/* webpackChunkName: "adminsPages" */'../views/Dispositivos'),
    meta: { requiresAuth: true }
  },
  {
    path: '/myuser',
    name: 'MyUser',
    component: () => import(/* webpackChunkName: "myuser" */'../components/Template/MyUser'),
    meta: { requiresAuth: true }
  },
  {
    path: '/tesseract',
    name: 'Tesseract',
    component: () => import(/* webpackChunkName: "tesseract" */'../views/Tesseract'),
    meta: { requiresAuth: true }
  },
  {
    path: '/regex',
    name: 'Regex',
    component: () => import(/* webpackChunkName: "tesseract" */'../views/Regex'),
    meta: { requiresAuth: true }
  },
  {
    path: '/filtroFornecedor',
    name: 'FiltroFornecedor',
    component: () => import(/* webpackChunkName: "FiltroFornecedor" */'../views/FiltroFornecedor'),
    meta: { requiresAuth: true }
  },
  {
    path: '/carregaOrdem',
    name: 'CarregaOrdem',
    component: () => import(/* webpackChunkName: "carregaOrdem" */'../views/CarregaOrdem'),
    meta: { requiresAuth: true }
  },
  {
    path: '/descarregaOrdem',
    name: 'DescarregaOrdem',
    component: () => import(/* webpackChunkName: "descarregaOrdem" */'../views/DescarregaOrdem'),
    meta: { requiresAuth: true }
  },
  {
    path: '/usuarioContrucao',
    name: 'UsuarioContrucao',
    component: () => import(/* webpackChunkName: "usuarioContrucao" */'../views/UsuarioContrucao'),
    meta: { requiresAuth: true }
  },
  {
    path: '/relatorioOrdem',
    name: 'RelatorioOrdem',
    component: () => import(/* webpackChunkName: "RelatorioOrdem" */'../views/RelatorioOrdem'),
    meta: { requiresAuth: true }
  },
  {
    path: '/relOrdem',
    name: 'RelOrdem',
    component: () => import(/* webpackChunkName: "RelOrdem" */'../views/RelOrdem'),
    meta: { requiresAuth: true }
  },
  {
    path: '/xml',
    name: 'XML',
    component: () => import(/* webpackChunkName: "xml" */'../views/XML'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onlyApp',
    name: 'OnlyApp',
    component: () => import(/* webpackChunkName: "OnlyApp" */'../views/OnlyApp'),
    meta: { requiresAuth: true }
  },
  {
    path: '/testeApp',
    name: 'TesteApp',
    component: () => import(/* webpackChunkName: "TesteApp" */'../views/TesteApp'),
    meta: { requiresAuth: true }
  },
  {
    path: '/relPosition',
    name: 'RelPosition',
    component: () => import(/* webpackChunkName: "RelPosition" */'../views/RelPosition'),
    meta: { requiresAuth: true }
  },
  {
    path: '/resumo',
    name: 'Resumo',
    component: () => import(/* webpackChunkName: "Resumo" */'../views/Resumo'),
    meta: { requiresAuth: true }
  },
  {
    path: '/avulso',
    name: 'Avulso',
    component: () => import(/* webpackChunkName: "Avulso" */'../views/Avulso'),
    meta: { requiresAuth: true }
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "Login" */ '../views/NotFound'),
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.name == "NotFound"){
    return next({ path: store.getters.getDefaultRoute })
  }
  let logged = store.getters.getLogged
  if (to.matched.some(record => record.meta.requiresAuth)) {
    logged ? next() : next({ path: '/login' })
  } else {
    next()
  }

})

export default router
