export default {
    state: {
        users: [
            { id: 1,value: 1, text: "Mauricio", type: "Motorista" , user: "mauricio" },
            { id: 2,value: 2, text: "Ezequiel", type: "Motorista", user: "ezequiel" },
            { id: 3,value: 3, text: "Everton", type: "Motorista", user: "everton" },
            { id: 4,value: 4, text: "Thiago", type: "Motorista", user: "thiago" },
            { id: 5,value: 5, text: "Diego", type: "Motorista", user: "diego" },
            { id: 6,value: 6, text: "Marcio", type: "Motorista", user: "marcio" },
        ],
    },
    mutations: {
        setDrivers(state, payload){
            state.users = payload
        },
        addDriver(state, payload) {
            let o = state.users.length
            state.users.push({ ...payload, value: o * 2 })
        },
        edtDriver(state, payload) {
            let idx = state.users.findIndex(e => e.value == payload.value)
            state.users.splice(idx, 1)
            state.users.push({ ...payload })
        },
        delDriver(state, payload) {
            let idx = state.users.findIndex(e => e.value == payload.value)
            state.users.splice(idx, 1)
        },
    },
    actions: {
        setDrivers(context, payload) {
            context.commit('setDrivers', payload)
            context.dispatch('persistData')
        },
        addDriver(context, payload) {
            context.commit('addDriver', payload)
            context.dispatch('persistData')
        },
        edtDriver(context, payload) {
            context.commit('edtDriver', payload)
            context.dispatch('persistData')
        },
        delDriver(context, payload) {
            context.commit('delDriver', payload)
            context.dispatch('persistData')
        },
    },
    getters: {
        getDrivers(state) {
            let d = []
            state.users.forEach(element => {
                if(element.type == "Motorista"){
                    d.push(element)
                }
            });
            return d
        },
        // getUsers(state) {
        //     return state.users
        // },
        getDriverNameById: (state) => (id) => {
            if (!state.users) {
                return id
            }
            let o = state.users.find(g =>
                g.value == id
            )
            return o ? `${o.value} - ${o.text}` : ''
        },
    },
}
