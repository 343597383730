import axios from 'axios'
export default {
  state: {
    drawer: true,
    menus:[],
    startNavigation: ""
  },
  mutations: {
    setDrawer(state, payload) {
      state.drawer = payload
    },
    setMenus(state, payload) {
      state.menus = payload
    },
    setStartNavigation(state, payload) {
      state.startNavigation = payload
    },
    toogleDrawer(state) {
      state.drawer = !state.drawer
    },
  },
  actions: {
    setDrawer(context, payload) {
      context.commit('setDrawer', payload)
    },
    setMenus(context, payload) {
      context.commit('setMenus', payload)
    },
    setStartNavigation(context, payload) {
      context.commit('setStartNavigation', payload)
    },
    loadMenus(context) {
      axios("nvmenu/user").then((resp) => {
          context.commit('setMenus', resp.data)
      });
  },
    toogleDrawer(context) {
      context.commit('toogleDrawer')
    },
  },
  getters: {
    getDrawer(state) {
      return state.drawer
    },
    getMenus(state) {
      return state.menus
    },
    getStartNavigation(state) {
      return state.startNavigation
    },
  },
}
