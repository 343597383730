import axios from 'axios'
export default {
    state: {
        devices: [],
    },
    mutations: {
        setDevices(state, payload) {
            state.devices = payload
        },
    },
    actions: {
        setDevices(context, payload) {
            context.commit('setDevices', payload)
        },
        loadDevices(context) {
            axios(`dispositivo`).then((resp) => {
                context.commit('setDevices', resp.data);
            });
        },
        saveDevice(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/dispositivo${finalUrl}`, payload).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Dispositivo salvo com Sucesso");
                    context.dispatch('loadDevices');
                }
            });
        },
        deletDevice(context, payload) {
            axios.delete(`/dispositivo/${payload.id}`).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Dispositivo deletado com Sucesso");
                    context.dispatch('loadDevices');
                }
            });
        },

    },
    getters: {
        getDevices(state) {
            return state.devices
        },
        getDeviceById: (state) => (id) => {
            if (!state.devices) {
                return id
            }
            let finded = state.devices.find(g =>
                g.id == id
            )
            return finded ? `${finded.id} - ${finded.name}` : ''
        },
        getDeviceByUniqueId: (state) => (uniqueId) => {
            if (!state.devices.length) {
                return uniqueId
            }
            let finded = state.devices.find(g =>
                g.identificador == uniqueId
            )
            return finded ? `${finded.id} - ${finded.name}` : ''
        },
    }
}