import axios from 'axios'
export default {
    state: {
        providers: [],
        provider: {},
        editionProvider: 0
    },
    mutations: {
        setProviders(state, payload) {
            state.providers = payload
        },
        setProvider(state, payload) {
            state.provider = payload
        },
        setEditionProvider(state, payload) {
            state.editionProvider = payload
        },
    },
    actions: {
        setProviders(context, payload) {
            context.commit('setProviders', payload)
        },
        setProvider(context, payload) {
            if (context.state.provider.name == payload.name) {
                context.commit('setProvider', {})
            } else {
                context.commit('setProvider', payload)
            }
        },
        setEditionProvider(context, payload) {
            context.commit('setEditionProvider', payload)
        },
        loadProviders(context) {
            axios(`fornecedores`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                    // element.coordinates = JSON.parse(element.coordinates)
                });
                context.commit('setProviders', resp.data)
            });
        },
        loadAllProviders(context) {
            axios(`fornecedores/all`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                    // element.coordinates = JSON.parse(element.coordinates)
                });
                context.commit('setProviders', resp.data)
            });
        },
        saveProvider(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            // payload.coordinates = JSON.stringify(payload.coordinates)
            axios[metodo](`/fornecedores${finalUrl}`, payload).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Fornecedor salvo com Sucesso");
                    context.dispatch('loadProviders')
                }
            });
        },
        deletProvider(context, payload) {
            axios.delete(`/fornecedores/${payload.id}`).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Fornecedor Deletado com Sucesso");
                    context.dispatch('loadProviders')
                }
            });
        },
    },
    getters: {
        getProviders(state) {
            return state.providers
        },
        getProvider(state) {
            return state.provider
        },
        getEditionProvider(state) {
            return state.editionProvider
        },
        getProvidersSelect(state) {
            let sel = []
            state.providers.forEach(e =>{
                sel.push({value: e.id, text:`${e.id} - ${e.name}`})
            })
            return sel
        },
        getProviderNameById: (state) => (id) => {
            if (!state.providers) {
                return id
            }
            let obj = state.providers.find(g =>
                g.id == id
            )
            return obj ? `${obj.id} - ${obj.name}` : ''
        },
        getProviderById: (state) => (id) => {
            if (!state.providers) {
                return id
            }
            let obj = state.providers.find(g =>
                g.id == id
            )
            return obj
        },
        getProviderHeaders() {
            return [
                { text: "ID", value: "id" },
                { text: "Nome", value: "name" },
                { text: "Provider Pai", value: "groupId" },
                { text: "Provider Pai Nome", value: "groupName" },
                { text: "Descrição", value: "description" },
                { text: "Ativo", value: "ativo" },
                { text: "Ações", value: "actions", sortable: false },
            ]
        }
    },
}