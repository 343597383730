import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router/index'
import template from './modules/template'
// import mapa from './modules/mapa'
// import ordem from './modules/ordem'
import vehicle from './modules/vehicle'
import users from './modules/users'
import snackbar from './modules/snackbar'
import parameters from './modules/parameters'
import grupo from './modules/grupo'
import usuarios from './modules/usuarios'
import cliente from './modules/cliente'
import ordem from './modules/ordens'
import construction from './modules/construction'
import provider from './modules/provider'
import BOtoUsr from './modules/BOtoUsr'
import Regex from './modules/Regex'
import filterProvider from './modules/filterProvider'
import usuarioContrucao from './modules/usuarioContrucao'
import dispositivos from './modules/dispositivos'
import avulso from './modules/avulso'
import dialogs from './modules/dialogs'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    token: null,
    logged: false,
    debugAxiosRequest: false,
    apiMapBoxKey: "pk.eyJ1IjoibWF1cmljaW9nb21lcyIsImEiOiJjbGZsMmJodTkwMHhlM3dxdnd4bnZldGlrIn0.4WTSA1aQLyGsbug_UF2GsA"
  },
  mutations: {
    persistData(state) {
      localStorage.setItem('cargas', JSON.stringify(state))
    },
    setLogged(state, payload) {
      state.logged = payload
    },
    setUser(state, payload) {
      state.user = payload
      state.logged = true
    },
    setToken(state, payload) {
      state.logged = true
      state.token = payload
      axios.defaults.headers.common['Authorization'] = `${payload}`
      localStorage.setItem('cargasD', payload)
      let data = payload.split('.')
      state.user = JSON.parse(atob(data[1]))
    },
    destroyUser(state, payload) {
      state.logged = false
      if (payload) {
        state.token = null
      }
      state.user = {}
      delete axios.defaults.headers.common['Authorization']
      localStorage.removeItem('cargasD')
      router.push('/login')
    },
    setdebugAxiosRequest(state, payload) {
      state.debugAxiosRequest = payload
    },
  },
  actions: {
    persistData(context) {
      context.commit('persistData')
    },
    getData(context) {
      let te = JSON.parse(localStorage.getItem('cargas'))
      if (te) {
        context.commit('setOrdens', te.ordem.ordems)
        context.commit('setConstructions', te.mapa.constructions)
        context.commit('setDrivers', te.users.users)
      }
    },
    logOff(context) {
      this.state.logged = false
      context.commit('destroyUser', [])
      delete axios.defaults.headers.common['Authorization']
      localStorage.removeItem('cargasD')
      // router.push({ path: '/login' })
    },
    setLogged(context, payload) {
      context.commit('setLogged', payload)
    },
    setdebugAxiosRequest(context, payload) {
      context.commit('setdebugAxiosRequest', payload)
    },
  },
  getters: {
    getUsuario(state) {
      return state.user
    },
    getMapBoxKey(state) {
      return state.apiMapBoxKey
    },
    getToken(state) {
      return state.token
    },
    getLogged(state) {
      return state.logged
    },
    getDefaultRoute(state) {
      return state.user.route
    },
    getDebugAxiosRequest(state) {
      return state.debugAxiosRequest
    },
  },
  modules: {
    template,
    // mapa,
    ordem,
    vehicle,
    users,
    snackbar,
    parameters,
    grupo,
    usuarios,
    cliente,
    construction,
    provider,
    BOtoUsr,
    Regex,
    filterProvider,
    usuarioContrucao,
    dispositivos,
    avulso,
    dialogs,
  }
})
