import axios from 'axios'
export default {
    state: {
        usuarioConstrucao: [],
        usuarioConstrucaoFilter: {
            constructionsId: [],
            usersId: [],
        }
    },
    mutations: {
        setUsuarioContrucao(state, payload) {
            state.usuarioConstrucao = payload
        },
        setUsuarioContrucaoFilter(state, payload) {
            state.usuarioConstrucaoFilter = payload
        },
    },
    actions: {
        setUsuarioContrucao(context, payload) {
            context.commit('setUsuarioContrucao', payload)
        },
        setUsuarioContrucaoFilter(context, payload) {
            context.commit('setUsuarioContrucaoFilter', payload)
        },
        loadUsuarioContrucao(context, payload) {
            let gp = payload ? `?groupId${payload}` : ''
            axios(`obraUsuario${gp}`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                });
                context.commit('setUsuarioContrucao', resp.data)
            });
        },
        loadAllUsuarioContrucao(context) {
            axios(`obraUsuario/all`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                });
                context.commit('setUsuarioContrucao', resp.data)
            });
        },
        saveObraUsuario(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/obraUsuario${finalUrl}`, payload).then(() => {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Obra / Usuario salvo com Sucesso");
                context.dispatch('loadUsuarioContrucao')
            });
        },
        deleteObraUsuario(context, payload) {
            axios.delete(`/obraUsuario/${payload.id}`).then(() => {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Obra / Usuario Deletado com Sucesso");
                context.dispatch('loadUsuarioContrucao')
            });
        },
    },
    getters: {
        getObraUsuario(state) {
            return state.usuarioConstrucao
        },
        getObraUsuarioFilter(state) {
            const filterModel = (arr = [], model = 0) => arr.length ? arr.includes(model) : true;
            let cuf = [...state.usuarioConstrucao];
            // se vier um array de ids de construções, filtra por eles
            if (Array.isArray(state.usuarioConstrucaoFilter.constructionsId)) {
                cuf = cuf.filter((item) => filterModel(state.usuarioConstrucaoFilter.constructionsId, parseInt(item.constructionId)));
            }
            // se vier um array de ids de construções, filtra por eles
            if (Array.isArray(state.usuarioConstrucaoFilter.usersId)) {
                cuf = cuf.filter((item) => filterModel(state.usuarioConstrucaoFilter.usersId, parseInt(item.userId)));
            }
            return cuf
        },
    },
}