import Vue from 'vue'
import axios from 'axios'
import store from '../store/index'

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = '/api/'
} else {
    // axios.defaults.baseURL = 'http://dobil.stv.com.br/api/'
    // axios.defaults.baseURL = 'https://cargas.stv.com.br/api/'
    axios.defaults.baseURL = 'http://localhost:3333/api/'
    // axios.defaults.baseURL = 'http://192.168.200.226:3334/api/'
}

const success = res => res
    // const error = res => res
const error = err => {
    console.log(err);
    switch (err.response.status) {
        case 400:
            store.dispatch('resetSnack')
            store.dispatch("showErrorSnack", `Erro ${err.response.data.error ? err.response.data.error :err.response.data}`);
            break;
        case 401:
            store.dispatch('resetSnack')
            store.dispatch("showErrorSnack", "Não Autorizado");
            break;
        case 404:
            store.dispatch('resetSnack')
            store.dispatch("showErrorSnack", `${err.response.data}`);
            break;
        case 406:
            store.dispatch('resetSnack')
            store.dispatch("showErrorSnack", `${err.response.data}`);
            break;
        case 307:
            store.dispatch('resetSnack')
            store.commit('destroyUser')
            store.dispatch("showErrorSnack", "Sua Seção Expirou");
            // window.location = '/'
            break;
        case 405:
            store.dispatch('resetSnack')
            store.dispatch("showErrorSnack", `Sem Permissão para usar o Recurso ${err.config.url}`);
            break;
        case 500:
            store.dispatch('resetSnack')
            store.dispatch("showErrorSnack", "Erro no Servidor");
            break;
        default:
            return Promise.reject(err)
    }
}

axios.interceptors.response.use(success, error)

axios.interceptors.request.use(function (config) {
    if(store.getters.getDebugAxiosRequest){
        console.log(config);
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

Vue.use({
    install(Vue) {
        Vue.prototype.$http = axios
    }
})