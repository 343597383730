import axios from 'axios'
export default {
    state: {
        clientes: [],
        cliente: {}
    },
    mutations: {
        setClientes(state, payload) {
            state.clientes = payload
        },
        setCliente(state, payload) {
            state.cliente = payload
        },
    },
    actions: {
        setClientes(context, payload) {
            context.commit('setClientes', payload)
        },
        setCliente(context, payload) {
            context.commit('setCliente', payload)
        },
        loadClientes(context, payload) {
            let gp = payload ? `?groupId${payload}` : ''
            axios(`cliente${gp}`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                    element.groupName = context.getters.getGroupNameById(element.groupId)
                });
                context.commit('setClientes', resp.data)
            });
        },
        loadAllClientes(context) {
            axios(`cliente/all`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                    element.groupName = context.getters.getGroupNameById(element.groupId)
                });
                context.commit('setClientes', resp.data)
            });
        },
        saveCliente(context, grupo) {
            const metodo = grupo.id ? "put" : "post";
            const finalUrl = grupo.id ? `/${grupo.id}` : "";
            axios[metodo](`cliente${finalUrl}`, grupo).then((resp) => {
                if (resp.status < 300) {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Cliente salvo com Sucesso");
                context.dispatch('loadClientes')
                }
            });
        },
        deletCliente(context, grupo) {
            axios.delete(`cliente/${grupo.id}`).then((resp) => {
                if (resp.status < 300) {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Cliente Deletado com Sucesso");
                context.dispatch('loadClientes')
                }
            });
        },
    },
    getters: {
        getClientes(state) {
            return state.clientes
        },
        getCliente(state) {
            return state.cliente
        },
        getClienteNameById: (state) => (id) => {
            if (!state.clientes) {
                return id
            }
            let gp = state.clientes.find(g =>
                g.id == id
            )
            return `${gp.id} - ${gp.name}`
        },
        getClienteHeaders() {
            return [
                { text: "ID", value: "id" },
                { text: "Nome", value: "name" },
                { text: "CNPJ", value: "cnpj" },
                { text: "Endereço", value: "address" },
                { text: "Telefone", value: "phone" },
                { text: "Grupo", value: "groupName" },
                { text: "Grupo do Veicular", value: "veicularGroupId" },
                { text: "Usuários", value: "usersAmount" },
                { text: "Descrição", value: "description" },
                { text: "Ativo", value: "ativo" },
                { text: "Ações", value: "actions", sortable: false },
            ]
        }
    },
}