<template>
  <div class="mapa">
    <div
      id="mapContainer"
      ref="ctn"
      class="d-flex align-self-stretch"
      :style="`height: ${alt}px; z-index:3`"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Icon } from "leaflet";

export default {
  name: "MapaFornecedor",
  props: {
    alt: {
      type: Number,
      default: 580,
    },
  },
  components: {},
  data() {
    return {
      map: null,
      lastMarker: null,
      cercas: null,
      click: false,
      coordinates: [],
      draw: null,
      providers: [],
      providersMarkers: [],
      mapLoaded: false,
    };
  },
  computed: {
    ...mapGetters(["getProviders", "getProvider"]),
  },
  methods: {
    clickMap(e) {
      this.click = true;
      if (this.coordinates.length) {
        this.draw.remove(this.map);
      }
      this.coordinates.push(e.latlng);
      this.draw = L.polygon(this.coordinates, { color: "green" }).addTo(
        this.map
      );
      //   this.draw = L.polygon(this.coordinates, { color: "green" }).bindTooltip("My polygon", {permanent: true, direction:"center", opacity:0.8}).addTo(this.map);
    },
    clearMap() {
      this.coordinates = [];
      this.draw.remove(this.map);
    },
    showAllProviders() {
      let coordinates = [];
      this.providers.forEach((e) => {
        e.remove(this.map);
      });
      this.providersMarkers.forEach((e) => {
        e.remove(this.map);
      });
      if (this.mapLoaded) {
        this.getProviders.forEach((element) => {
          let a = L.polygon(element.coordinates, { color: element.color })
            .bindTooltip(element.name)
            .addTo(this.map);
          coordinates.push(element.coordinates);
          this.providers.push(a);
          let b = L.marker(element.coordinateCenter)
            .bindTooltip(element.name)
            .addTo(this.map);
          this.providersMarkers.push(b);
        });
        if (coordinates.length) {
          this.map.fitBounds(coordinates);
        } else {
          setTimeout(this.showAllProviders, 1000);
        }
      } else {
        setTimeout(this.showAllProviders, 1000);
      }
    },
    showProvider(val) {
      if (val.coordinates) {
        if (this.draw) {
          this.draw.remove(this.map);
        }
        if (this.providers.length) {
          this.providers.forEach((e) => {
            e.remove(this.map);
          });
        }
        this.providersMarkers.forEach((e) => {
          e.remove(this.map);
        });
        this.draw = L.polygon(val.coordinates, { color: val.color })
          .bindTooltip(val.name, {
            permanent: true,
            direction: "center",
            opacity: 0.8,
          })
          .addTo(this.map);
        this.map.fitBounds(val.coordinates);
      } else {
        // se não tenho uma obra selecionada mostro todas
        if (this.draw) {
          this.draw.remove(this.map);
        }
        if (this.map) {
          this.showAllProviders();
        }
      }
    },
  },
  watch: {
    getProvider: {
      // immediate: true,
      handler(val) {
        if (this.mapLoaded) {
          this.showProvider(val);
        } else {
          setTimeout(this.showAllProviders(), 200);
        }
      },
    },
    getProviders: {
      // immediate: true,
      handler(val) {
        if (val.length) {
          if (this.mapLoaded) {
            this.showAllProviders();
          }
        }
      },
    },
  },
  mounted() {
    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
    this.map = L.map("mapContainer").setView(
      [-14.337566608887226, -54.93164062500001],
      4
    );
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=" + this.$store.getters.getMapBoxKey,
      {
        maxZoom: 18,
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
          '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
          'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        id: "mapbox/streets-v11",
        tileSize: 512,
        zoomOffset: -1,
      }
    ).addTo(this.map);
    let VUE = this;
    this.map.whenReady(function () {
      VUE.mapLoaded = true;
      VUE.showAllProviders();
    });
    // this.showAllProviders()
  },
};
</script>

<style scoped>
#mapContainer {
  width: 100%;
}
</style>