<template>
  <v-card>
    <v-card-title> Ordens de Compras (OC) </v-card-title>
    <v-card-text>
      <v-card>
        <v-card-title>
          {{ ordem.id ? "Editar" : "Nova" }} Ordem
          <v-spacer></v-spacer>
          <!-- <v-file-input
            chips
            label="Selecione o excell"
            v-model="excel"
            @change="lerArquivo"
            accept="application\vnd.ms-excel"
          ></v-file-input> -->
          <v-spacer></v-spacer>
          <v-btn @click="showForm = !showForm" color="warning"
            >{{ showForm ? "Esconder" : "Mostrar" }} Formulário</v-btn
          >
        </v-card-title>
        <v-card-text v-show="showForm">
          <v-row>
            <v-col>
              <v-text-field
                label="Numero da Ordem"
                v-model="ordem.buyOrder"
                hint="Numero usando para identificar a ordem"
                persistent-hint
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Identificador ERP"
                v-model="ordem.erpIdentifier"
                hint="Parametro usando para identificar a ordem"
                persistent-hint
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-textarea
                outlined
                rows="1"
                label="Descrição / Observações"
                v-model="ordem.description"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Nome da Order"
                v-model="ordem.name"
                hint="Nome usando para identificar a ordem"
                persistent-hint
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="ordem.constructionId"
                :items="getConstructionsSelect"
                outlined
                label="Selecione a Obra que receberá a mercadoria"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="ordem.providerId"
                :items="getProvidersSelect"
                outlined
                label="Selecione o Fornecedor que possui a mercadoria"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>
                  Informe os items da OC ({{ ordem.buyOrderItems.length }})
                  <v-spacer></v-spacer>
                  <v-btn @click="addItem">Adicionar Items</v-btn>
                </v-card-title>
                <v-card-text>
                  <v-row
                    v-for="(item, index) in ordem.buyOrderItems"
                    :key="index"
                  >
                    <v-col>
                      <v-text-field
                        label="Codigo Fornecedor"
                        v-model="ordem.buyOrderItems[index].providerCode"
                        hint="Codigo de controle interno do fornecedor"
                        persistent-hint
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Nome do Item"
                        v-model="ordem.buyOrderItems[index].name"
                        hint="Descrição do Item"
                        persistent-hint
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col v-show="!ordem.buyOrderItems[index].id">
                      <v-text-field
                        label="Quantidade"
                        v-model="ordem.buyOrderItems[index].amount"
                        hint="Quantidade do Item"
                        persistent-hint
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        label="Unidade de Medida"
                        v-model="ordem.buyOrderItems[index].unity"
                        hint="Unidade de medida do Item"
                        persistent-hint
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-text-field
                        label="Cor"
                        v-model="ordem.buyOrderItems[index].color"
                        hint="Sel. uma cor"
                        persistent-hint
                        outlined
                        type="color"
                      >
                        <template v-slot:prepend-inner>
                          <template>
                            <v-icon :color="ordem.buyOrderItems[index].color">
                              mdi-circle
                            </v-icon>
                          </template>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="auto" class="mt-2">
                      <v-btn
                        color="red"
                        elevation="2"
                        icon
                        @click="delItem(index)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn color="warning" @click="clearForm">Cancelar</v-btn>
            <v-btn class="mx-2" color="success" @click="save">Salvar</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="my-2">
        <v-card-title>
          <v-row>
            <v-col cols="auto" class="mt-2">
              <span> Ordens Cadastradas </span>
            </v-col>
            <v-col>
              <v-autocomplete
                :items="statusOptions"
                outlined
                label="Filtra por status"
                clearable
                v-model="status"
                @change="load"
              ></v-autocomplete>
            </v-col>
            <v-col cols="auto" class="mt-2">
              <v-btn @click="$store.dispatch('loadOrders')"> Atualizar </v-btn>
            </v-col>
            <!-- <v-spacer></v-spacer> -->
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-simple-table height="500px">
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Numero da Ordem</th>
                <th class="text-left">Nome da Ordem</th>
                <th class="text-left">Obra</th>
                <th class="text-left">Fornecedor</th>
                <th class="text-left">Items</th>

                <th class="text-left">Descrição</th>
                <th class="text-left">Cadastro</th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in getOrders" :key="index">
                <td>{{ item.id }}</td>
                <td>{{ item.buyOrder }}</td>
                <td>{{ item.name }}</td>
                <td>{{ getConstructionNameById(item.constructionId) }}</td>
                <td>{{ getProviderNameById(item.providerId) }}</td>
                <td>
                  <div
                    v-for="(it, idx) in item.buyOrderItems"
                    :key="idx"
                    class="my-3"
                  >
                    <OrdemItems :item="it" />
                  </div>
                </td>
                <td>{{ item.description }}</td>
                <!-- <td>{{ item.createdAt == item.updatedAt  ? new Date(item.createdAt).toLocaleString() : `${Date(item.createdAt).toLocaleString()} / ${Date(item.updatedAt).toLocaleString()}` }}</td> -->
                <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
                <td>
                  <v-icon small @click="del(item)" class="m-1">
                    mdi-trash-can
                  </v-icon>
                  <v-icon small @click="edt(item)"> mdi-lead-pencil </v-icon>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import OrdemItems from "../components/Ordem/Item";
export default {
  name: "ordem",
  components: {
    OrdemItems,
  },
  data() {
    return {
      showForm: false,
      excel: null,
      ordem: {
        buyOrderItems: [{}],
      },
      statusOptions: [
        { text: "Todas", value: "ALL" },
        { text: "Com saldo", value: "OPEN" },
        { text: "Sem saldo", value: "CLOSED" },
      ],
      status: "",
    };
  },
  computed: {
    ...mapGetters([
      "getConstructions",
      "getOrders",
      "getConstructionNameById",
      "getProviderNameById",
      "getConstructionsSelect",
      "getProvidersSelect",
    ]),
  },
  methods: {
    load(status) {
      this.$store.dispatch("loadOrders", status);
    },
    save() {
      this.$store.dispatch("saveOrder", this.ordem);
    },
    clearForm() {
      console.log("clearForm");
    },
    del(e) {
      this.$store.dispatch("deletOrder", e);
    },
    edt(e) {
      this.ordem = e;
      this.showForm = true;
    },
    addItem() {
      this.ordem.buyOrderItems.push({});
    },
    delItem(index) {
      this.ordem.buyOrderItems.splice(index, 1);
    },
    lerArquivo() {
      if (!this.excel) {
        return;
      }
      let formData = new FormData();
      formData.append("excel", this.excel);
      let config = {
        method: "post",
        url: "ordemcompra/import",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      this.$http(config).then((resp) => {
        if (resp.status == 200) {
          console.log(resp.data);
          this.$store.dispatch("resetSnack");
          this.$store.dispatch("showSuccessSnack", "Importado com Sucesso");
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch("loadOrders");
    this.$store.dispatch("loadConstructions");
    this.$store.dispatch("loadProviders");
  },
};
</script>

<style>
</style>