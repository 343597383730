<template>
  <v-card class="my-1">
    <v-card-title>
      {{ fornecedor.id ? "Editar" : "Nova" }} Fornecedor
      <v-spacer></v-spacer>
      <v-btn @click="showForm = !showForm" color="warning"
        >{{ showForm ? "Esconder" : "Mostrar" }} Formulario</v-btn
      >
    </v-card-title>
    <v-card-text v-show="showForm">
      <v-row>
        <div
          id="mapForm"
          ref="ctn"
          :style="`height: ${alt}vh; z-index:3`"
        ></div>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Endereço"
            v-model="fornecedor.address"
            hint="Buscar cordenadas pelo endereço"
            append-icon="mdi-send"
            @click:append="busca"
            @keypress.enter="busca"
            persistent-hint
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Latitude"
            v-model.number="cordenadas.latitude"
            hint="Adicione a Latitude para mover o mapa"
            persistent-hint
            outlined
            type="number"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Longitude"
            v-model.number="cordenadas.longitude"
            hint="Adicione a Longitude para mover o mapa"
            append-icon="mdi-send"
            @click:append="moveMap"
            @keypress.enter="moveMap"
            persistent-hint
            outlined
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Nome do Fornecedor"
            v-model="fornecedor.name"
            hint="Nome que será usando para identificar a fornecedor"
            persistent-hint
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="auto">
              <v-btn :color="fornecedor.color" fab x-small class="mt-2"></v-btn>
            </v-col>
            <v-col>
              <v-text-field
                label="Cor do desenho"
                v-model="fornecedor.color"
                hint="Cor do desenho"
                persistent-hint
                outlined
                type="color"
                :color="fornecedor.color"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-textarea
            outlined
            rows="1"
            label="Descrição / Observações"
            v-model="fornecedor.description"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <span class="mx-4">Quantidades de Pontos:</span>
        <p
          class="Button"
          :class="
            fornecedor.coordinates.length > 2 ? 'green--text' : 'red--text'
          "
        >
          {{ fornecedor.coordinates.length }}
        </p>
        <v-spacer></v-spacer>
        <v-btn color="warning" @click="clearForm">Cancelar</v-btn>
        <v-btn class="mx-2" color="success" @click="save">Salvar</v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Icon } from "leaflet";

export default {
  name: "LeafletMap",
  props: {
    alt: {
      type: Number,
      default: 50,
    },
    constructions: {
      type: Array,
    },
  },
  components: {},
  data() {
    return {
      map: null,
      lastMarker: null,
      markersAddress: [],
      cercas: null,
      click: false,
      coordinates: [],
      draw: null,
      endereco: "",
      showForm: true,
      fornecedor: {
        color: "#FF0000",
        coordinates: [],
      },
      cordenadas: {
        latitude: 0,
        longitude: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["getEditionProvider", "getProviders"]),
  },
  methods: {
    clickMap(e) {
      this.$emit("click", e);
      if (this.fornecedor.coordinates.length) {
        this.draw.remove(this.map);
      }
      this.fornecedor.coordinates.push([e.latlng.lat, e.latlng.lng]);
      if (this.fornecedor.name) {
        this.draw = L.polygon(this.fornecedor.coordinates, {
          color: this.fornecedor.color,
        })
          .bindTooltip(this.fornecedor.name, {
            permanent: true,
            direction: "center",
            opacity: 0.8,
          })
          .addTo(this.map);
      } else {
        this.draw = L.polygon(this.fornecedor.coordinates, {
          color: this.fornecedor.color,
        }).addTo(this.map);
      }
    },
    clearMap() {
      this.fornecedor.coordinates = [];
      this.draw.remove(this.map);
    },
    save() {
      if (
        !this.fornecedor.name ||
        this.fornecedor.coordinates.length < 2 ||
        !this.fornecedor.color
      ) {
        alert("Nome, cor e 3 pontos no minimo são obrigatorios ");
        return;
      }
      this.$store.dispatch("saveProvider", this.fornecedor);
      this.clearForm();
    },
    clearForm() {
      (this.fornecedor = {
        color: "#FF0000",
        coordinates: [],
      }),
        this.clearMap();
    },
    busca() {
      // rua austria 811, novo hamburgo
      let bound = [];
      this.markersAddress.forEach((e) => {
        e.remove(this.map);
      });
      this.markersAddress = [];
      this.$store.dispatch("resetSnack");
      this.$store.dispatch("showSuccessSnack", "Buscando...");
      this.$http(`reverse/address?address=${this.fornecedor.address}`).then(
        (resp) => {
          if (resp.data.length == 0) {
            let VUE = this;
            setTimeout(() => {
              VUE.$store.dispatch("resetSnack");
              VUE.$store.dispatch(
                "showErrorSnack",
                "Não encontrei nada pelo endereço"
              );
            }, 300);
          }
          resp.data.forEach((element) => {
            let m = L.marker([element.lat, element.lon])
              .bindTooltip(
                `<table>
                   <tr>
                      <td>Local:</td>
                      <td style="text-align: center;">${element.display_name.replaceAll(
                        ",",
                        "<br>"
                      )}</td>
                    </tr>
              </table>`
              )
              .addTo(this.map);
            bound.push([element.lat, element.lon]);
            this.markersAddress.push(m);
          });
          if (bound.length) {
            this.map.fitBounds(bound);
          }
          this.$store.dispatch("resetSnack");
        }
      );
    },
    moveMap() {
      if (this.cordenadas.latitude == 0 && this.cordenadas.longitude == 0) {
        return;
      }
      let m = L.marker([
        this.cordenadas.latitude,
        this.cordenadas.longitude,
      ]).addTo(this.map);
      // this.map.fitBounds([this.cordenadas.latitude, this.cordenadas.longitude])
      this.map.setView(
        [this.cordenadas.latitude, this.cordenadas.longitude],
        15
      );
      this.markersAddress.push(m);
    },
  },
  watch: {
    getEditionProvider: {
      handler(val) {
        if (val) {
          this.fornecedor = this.getProviders.find((e) => e.id == val);
          this.showForm = true;
          this.draw = L.polygon(this.fornecedor.coordinates, {
            color: this.fornecedor.color,
          })
            .bindTooltip(this.fornecedor.name, {
              permanent: true,
              direction: "center",
              opacity: 0.8,
            })
            .addTo(this.map);
          this.map.fitBounds(this.fornecedor.coordinates);
          // se vier as coordenadas do centro do fornecedor adiciono o marcador
          if(Array.isArray(this.fornecedor.coordinateCenter) && this.fornecedor.coordinateCenter.length == 2){
            let m = L.marker([
              this.fornecedor.coordinateCenter[0],
              this.fornecedor.coordinateCenter[1],
            ]).addTo(this.map);
            this.markersAddress.push(m);
            // adiciono as coordenadas no formulario
            this.cordenadas.latitude = this.fornecedor.coordinateCenter[0];
            this.cordenadas.longitude = this.fornecedor.coordinateCenter[1];
           
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
    this.map = L.map("mapForm").setView(
      [-14.337566608887226, -54.93164062500001],
      4
    );
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=" +
        this.$store.getters.getMapBoxKey,
      {
        maxZoom: 18,
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
          '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
          'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        id: "mapbox/streets-v11",
        tileSize: 512,
        zoomOffset: -1,
      }
    ).addTo(this.map);
    this.map.on("click", this.clickMap);
    this.map.on("contextmenu", this.clearMap);
    // let VUE = this;
    // this.map.whenReady(function () {
    //   VUE.showForm = false;
    // });
  },
};
</script>

<style scoped>
#mapForm {
  width: 100%;
}
</style>