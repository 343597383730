import axios from 'axios'
export default {
    state: {
        filtersproviders: [],
        filterprovider: {},
        editionFilterProvider: 0
    },
    mutations: {
        setFiltersProviders(state, payload) {
            state.filtersproviders = payload
        },
        setFilterProvider(state, payload) {
            state.filterprovider = payload
        },
        setEditionFilterProvider(state, payload) {
            state.editionFilterProvider = payload
        },
    },
    actions: {
        setFiltersProviders(context, payload) {
            context.commit('setFiltersProviders', payload)
        },
        setFilterProvider(context, payload) {
            if (context.state.filterprovider.name == payload.name) {
                context.commit('setFilterProvider', {})
            } else {
                context.commit('setFilterProvider', payload)
            }
        },
        seteditionFilterProvider(context, payload) {
            context.commit('setEditionFilterProvider', payload)
        },
        loadFiltersProviders(context) {
            axios(`filterProvider`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                });
                context.commit('setFiltersProviders', resp.data)
            });
        },
        loadAllFiltersProvider(context) {
            axios(`filterProvider/all`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                });
                context.commit('setFiltersProviders', resp.data)
            });
        },
        saveFilterProvider(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            payload.coordinates = JSON.stringify(payload.coordinates)
            axios[metodo](`/filterProvider${finalUrl}`, payload).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Filtro salvo com Sucesso");
                    context.dispatch('loadFiltersProviders')
                }
            });
        },
        deletFiltersProvider(context, payload) {
            axios.delete(`/filterProvider/${payload.id}`).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Filtro Deletado com Sucesso");
                    context.dispatch('loadFiltersProviders')
                }
            });
        },
    },
    getters: {
        getFiltersProviders(state) {
            return state.filtersproviders
        },
        getFilterProvider(state) {
            return state.filterprovider
        },
        getEditionFiltersProviders(state) {
            return state.editionFilterProvider
        },
        getFiltersProvidersSelect(state) {
            let sel = []
            state.filtersproviders.forEach(e =>{
                sel.push({value: e.id, text:`${e.id} - ${e.name}`})
            })
            return sel
        },
        getFiltersProvidersNameById: (state) => (id) => {
            if (!state.filtersproviders) {
                return id
            }
            let obj = state.filtersproviders.find(g =>
                g.id == id
            )
            return obj ? `${obj.id} - ${obj.name}` : ''
        },
        getFiltersProvidersById: (state) => (id) => {
            if (!state.filtersproviders) {
                return id
            }
            let obj = state.filtersproviders.find(g =>
                g.id == id
            )
            return obj
        },
    },
}