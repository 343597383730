import axios from 'axios'
export default {
    state: {
        order: [],
        orderReport: [],
        orderItems:[],
        orderReportFilter:{},
        orderFilter:{
            constructionsId: [],
            status: "OPEN"
        }
    },
    mutations: {
        setOrder(state, payload) {
            state.order = payload
        },
        setOrderFilter(state, payload) {
            state.orderFilter = payload
        },
        setOrderItems(state, payload) {
            state.orderItems = payload
        },
        setOrderReport(state, payload) {
            state.orderReport = payload
        },
        setOrderReportFilter(state, payload) {
            state.orderReportFilter = payload
        },
    },
    actions: {
        setOrder(context, payload) {
            context.commit('setOrder', payload)
        },
        setOrderFilter(context, payload) {
            context.commit('setOrderFilter', payload)
        },
        setOrderItems(context, payload) {
            context.commit('setOrderItems', payload)
        },
        setOrderReport(context, payload) {
            context.commit('setOrderReport', payload)
        },
        setOrderReportFilter(context, payload) {
            context.commit('setOrderReportFilter', payload)
            localStorage.setItem("orderReportFilter", JSON.stringify(payload))
        },
        loadOrders(context, payload) {
            let urlEnd = payload? `?status=${payload}` : "";
            axios(`ordemcompra${urlEnd}`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                });
                context.commit('setOrder', resp.data)
            });
        },
        loadOrdersItems(context) {
            axios(`ordemcompra/items`).then((resp) => {
                context.commit('setOrderItems', resp.data);
            });
        },
        loadAllOrders(context) {
            axios(`ordemcompra/all`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                });
                context.commit('setOrder', resp.data)
            });
        },
        getReportBO(context, payload) {
            axios.post(`ordemcompra/report`, {...payload}).then((resp) => {
                context.commit('setOrderReport', resp.data);
            });
        },
        saveOrder(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/ordemcompra${finalUrl}`, payload).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Ordem salva com Sucesso");
                    context.dispatch('loadOrders')
                }
            });
        },
        deletOrder(context, payload) {
            axios.delete(`/ordemcompra/${payload.id}`).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Ordem Deletada com Sucesso");
                    context.dispatch('loadOrders')
                }
            });
        },
    },
    getters: {
        getOrders(state) {
            return state.order
        },
        getOrdersFiltred (state) {
            const filterModel = (arr = [], model = 0) => arr.length ? arr.includes(model) : true;
            let of = [...state.order];
            // se vier um array de ids de construções, filtra por eles
            if (Array.isArray(state.orderFilter.constructionsId)) {
                of = of.filter((order) => filterModel(state.orderFilter.constructionsId, order.constructionId));
            }
            // filtro pelos os status das ordens
            if(state.orderFilter.status){
                if(state.orderFilter.status == "ALL"){
                    return of
                }
                if(state.orderFilter.status == "OPEN"){            
                    return of.filter((order) => {
                        let amout = order.buyOrderItems.reduce((acc, item) => acc + item.amount, 0);
                        return amout > 0;
                    });
                }
                if(state.orderFilter.status == "CLOSED"){            
                    return of.filter((order) => {
                        let amout = order.buyOrderItems.reduce((acc, item) => acc + item.amount, 0);
                        return amout <= 0;
                    });
                }
            }
            return of;
        },
        getOrdersReport(state) {
            return state.orderReport
        },
        getOrderReportFilter(state) {
            if(state.orderReportFilter.length){
                return state.orderReportFilter
            }else{
                return JSON.parse(localStorage.getItem("orderReportFilter") || "[]")
            }
        },
        getOrderNameById: (state) => (id) => {
            if (!state.order) {
                return id
            }
            let gp = state.order.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
        getOrderById: (state) => (id) => {
            if (!state.order) {
                return id
            }
            let gp = state.order.find(g =>
                g.id == id
            )
            return gp
        },
        getItemOrdemById: (state) => (orderId, itemId) => {
            if (!state.order) {
                return orderId
            }
            let oc = state.order.find(g =>
                g.id == orderId
            )
            let it
            if(oc){
                it = oc.buyOrderItems.find(g =>
                    g.id == itemId
                )
            }
            return it
        },
        getOrdemSelect(state) {
            let sel = []
            state.order.forEach(e =>{
                sel.push({value: e.id, text:`${e.id} - ${e.name}`})
            })
            return sel
        },
        getOrderItems(state) {
            return state.orderItems
        },
        getOrderItemsSelect(state) {
            let sel = []
            state.orderItems.forEach(e =>{
                sel.push({value: e.id, text:`${e.id} - ${e.name} - OC:${e.buyOrderId}`})
            })
            return sel
        },
        getOrderHeaders() {
            return [
                { text: "ID", value: "id" },
                { text: "Nome", value: "name" },
                { text: "Ordem Pai", value: "groupId" },
                { text: "Ordem Pai Nome", value: "groupName" },
                { text: "Descrição", value: "description" },
                { text: "Ativo", value: "ativo" },
                { text: "Ações", value: "actions", sortable: false },
            ]
        }
    },
}