<template>
  <div>
    <v-row class="mt-1">
      <v-col>
        <v-card max-height="600">
          <v-card-text>
            <MapaDashBoard />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-btn
        color="success"
        fab
        dark
        small
        absolute
        right
        class="m-3"
        @click="showOc = !showOc"
      >
        <v-icon>{{ showOc ? "mdi-eye-off" : "mdi-eye" }}</v-icon>
      </v-btn>
      <v-col v-show="showOc">
        <v-card height="600">
          <v-card-title>
            Ordens Iniciadas
            <v-spacer></v-spacer>
            <v-btn @click="$store.dispatch('LoadBOAvailable')">Atualizar</v-btn>
          </v-card-title>
          <v-card-text>
            <v-simple-table>
              <thead>
                <tr>
                  <th class="text-left">ID</th>
                  <th class="text-left">OC</th>
                  <th class="text-left">Fornecedor</th>
                  <th class="text-left">Obra</th>
                  <th class="text-left">Iniciada</th>
                  <th class="text-left">Placa</th>
                  <th class="text-left">Motorista</th>
                  <th class="text-left">Última Posição</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in getBOAvailable" :key="item.name">
                  <td>{{ item.id }}</td>
                  <td>{{ item.order.id }} {{ item.order.erpIdentifier }}</td>
                  <td>{{ getProviderNameById(item.order.providerId) }}</td>
                  <td>
                    {{ getConstructionNameById(item.order.constructionId) }}
                  </td>
                  <td>{{ new Date(item.loadedDate).toLocaleString() }}</td>
                  <td>
                    {{
                      `${
                        item.plate ? item.plate : "não informado"
                      }`.toUpperCase()
                    }}
                  </td>
                  <td>{{ getUserNameById(item.userId) }}</td>
                  <td>
                    {{
                      item.position
                        ? new Date(item.position.fixTime).toLocaleString()
                        : "Sem posição"
                    }}
                  </td>
                  <!-- <td>{{ new Date(item.position.fixTime).toLocaleString() }}</td> -->
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MapaDashBoard from "../components/Mapa/MapaDashBoard";
import { mapGetters } from "vuex";

export default {
  name: "DashBoard",

  components: {
    MapaDashBoard,
  },
  computed: {
    ...mapGetters([
      "getConstructions",
      "getOrders",
      "getConstructionNameById",
      "getProviderNameById",
      "getConstructionsSelect",
      "getProvidersSelect",
      "getUserSelect",
      "getBOtoUsers",
      "getItemOrdemById",
      "getOrderById",
      "getUserNameById",
      "getBOAvailable",
      "getUserNameById",
      "getProviderNameById",
    ]),
  },
  data: () => ({
    showOc: true,
    autoUpdate: null,
  }),
  mounted() {
    this.$store.dispatch("loadOrders");
    this.$store.dispatch("loadProviders");
    this.$store.dispatch("loadUsers");
    this.$store.dispatch("LoadBOAvailable");
    this.$store.dispatch("loadConstructions");
    this.$store.dispatch("loadOrdersItems");
    // ativa a auto atualização
    this.autoUpdate = setInterval(() => {
      this.$store.dispatch("LoadBOAvailable");
    }, 60000);
  },
  beforeDestroy() {
    // desativa a auto atualização
    clearInterval(this.autoUpdate);
  },
};
</script>
