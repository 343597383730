import axios from 'axios'
export default {
    state: {
        usuarios: [],
        types: []
    },
    mutations: {
        setUsers(state, payload) {
            state.usuarios = payload
        },
        setTypes(state, payload) {
            state.types = payload
        },
    },
    actions: {
        setUsers(context, payload) {
            context.commit('setUsers', payload)
        },
        setTypes(context, payload) {
            context.commit('setTypes', payload)
        },
        loadUsers(context, payload) {
            let complement = ''
            if (payload) {
                complement = "?excluded=true"
            }
            axios(`usuario${complement}`).then((resp) => {
                resp.data.forEach((ele) => {
                    ele.ativo = ele.enabled ? "SIM" : "NÃO";
                });
                context.dispatch('setUsers', resp.data)
            });
        },
        loadUsersTypes(context) {
            axios(`nvacesso`).then((resp) => {
                resp.data.forEach((ele) => {
                    ele.ativo = ele.enabled ? "SIM" : "NÃO";
                });
                context.dispatch('setTypes', resp.data)
            });
        },
        saveUser(context, user) {
            const metodo = user.id ? "put" : "post";
            if (user.id) {
                if (user.password == "") {
                    delete user.password;
                }
            }
            const finalUrl = user.id ? `/${user.id}` : "";
            axios[metodo](`/usuario${finalUrl}`, user).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Usuário salvo com Sucesso");
                    context.dispatch('loadUsers')
                }
            });
        },
        changePass(context, user) {
            console.log(user);
            // const metodo = user.id ? "put" : "post";
            // if (user.id) {
            //     if (user.password == "") {
            //         delete user.password;
            //     }
            // }
            const finalUrl = user.id ? `/${user.id}` : "";
            axios.put(`usuario/passADM${finalUrl}`, user).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Senha do Usuário Alterada com Sucesso");
                    context.dispatch('loadUsers')
                }
            });
        },
        deleteUser(context, user) {
            axios.delete(`/usuario/${user.id}`).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch(
                        "showSuccessSnack",
                        "Usuário deletado com Sucesso!"
                    );
                    context.dispatch('loadUsers')
                }
            });
        },
    },
    getters: {
        getUsers(state) {
            return state.usuarios
        },
        setTypes(state) {
            return state.types
        },
        getTypesSelect(state) {
            let tp = []
            state.types.forEach(e => {
                tp.push({ value: e.id, text: `${e.id} - ${e.name}` })
            })
            return tp
        },
        getUserSelect(state) {
            let tp = []
            state.usuarios.forEach(e => {
                tp.push({ value: e.id, text: `${e.id} - ${e.name}` })
            })
            return tp
        },
        getUserNameById: (state) => (id) => {
            if (id == "0") {
                return "Sistema";
            }
            if (!state.usuarios) {
                return id
            }
            let gp = state.usuarios.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
        getTraceableById: (state) => (id) => {
            if (!state.usuarios) {
                return id
            }
            let gp = state.usuarios.find(g =>
                g.id == id
            )
            return gp.traceable ? gp.traceable : false
        },
        getUserById: (state) => (id) => {
            if (!state.usuarios) {
                return id
            }
            let gp = state.usuarios.find(g =>
                g.id == id
            )
            return gp
        },
        getTableHeader() {
            return [
                { text: "ID", value: "id" },
                {
                    text: "Imagem",
                    align: "center",
                    filterable: false,
                    value: "picture",
                },
                { text: "Nome", value: "name" },
                { text: "Usuário", value: "userName" },
                { text: "Descrição", value: "description" },
                { text: "Tipo", value: "acessoName" },
                { text: "Grupo", value: "groupName" },
                { text: "DeviceTraccar", value: "uniqueId" },
                { text: "Ativo", value: "ativo" },
                { text: "Ações", value: "actions", sortable: false },
            ];
        }
    }
}