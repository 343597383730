<template>
  <v-container fluid>
    <v-card outlined>
      <v-card-title>
        <v-row>
          <v-col cols="auto" class="mt-2">
            <span> Enviar Ordem para o Usuário </span>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="getConstructionsSelect"
              outlined
              label="Obra(s)"
              chips
              multiple
              small-chips
              deletable-chips
              counter
              clearable
              v-model="filter.constructionsId"
              @change="applyFilter"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="statusOptions"
              outlined
              label="Filtra por status"
              clearable
              v-model="filter.status"
              @change="applyFilter"
            ></v-autocomplete>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="success"
              x-large
              @click="$store.dispatch('loadOrders')"
            >
              Atualizar
            </v-btn>
          </v-col>
        </v-row>
        <!-- Enviar Ordem para o Usuário
        <v-spacer></v-spacer>
        <v-btn @click="$store.dispatch('loadOrders')">Atualizar</v-btn> -->
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">Numero da Ordem</th>
              <th class="text-left">Nome da Ordem</th>
              <th class="text-left">Obra</th>
              <th class="text-left">Fornecedor</th>
              <th class="text-center">Items</th>
              <th class="text-left">Descrição</th>
              <th class="text-left">Cadastro</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in getOrdersFiltred" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.buyOrder }}</td>
              <td>{{ item.name }}</td>
              <td>{{ getConstructionNameById(item.constructionId) }}</td>
              <td>{{ getProviderNameById(item.providerId) }}</td>
              <td>
                <div
                  v-for="(it, idx) in item.buyOrderItems"
                  :key="idx"
                  class="my-3"
                >
                  <OrdemItems
                    :item="it"
                    :enableSend="true"
                    @itemToSend="itemToSend($event)"
                  />
                </div>
              </td>
              <td>{{ item.description }}</td>
              <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card class="mt-3" outlined>
      <v-card-title>
        <v-row>
          <v-col cols="auto" class="mt-2">
            <span> Ordens aguardando carga </span>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="getConstructionsSelect"
              outlined
              label="Obra(s)"
              chips
              multiple
              small-chips
              deletable-chips
              counter
              clearable
              v-model="filterBU.constructionsId"
              @change="applyFilterBO"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="getUserSelect"
              outlined
              label="Motoristas"
              chips
              multiple
              small-chips
              deletable-chips
              counter
              clearable
              v-model="filterBU.users"
              @change="applyFilterBO"
            ></v-autocomplete>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="success"
              x-large
              @click="$store.dispatch('loadBOtoUser')"
            >
              Atualizar
            </v-btn>
          </v-col>
        </v-row>
        <!-- Ordens aguardando carga <v-spacer></v-spacer>
        <v-btn @click="$store.dispatch('loadBOtoUser')"> Atualizar </v-btn> -->
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">Ordem de Compra</th>
              <th class="text-center">Item</th>
              <th class="text-center">Carregado</th>
              <th class="text-left">Usuário Coleta</th>
              <th class="text-left">Quantidade</th>
              <th class="text-left">Descrição</th>
              <th class="text-left">Enviado Por</th>
              <th class="text-left">Cadastro</th>
              <th class="text-left">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in getOrdersBOFiltred" :key="index">
              <td>{{ item.id }}</td>
              <!-- <td>{{ item.buyOrderId }}</td> -->
              <td>
                <v-card>
                  <v-card-text>
                    <v-row justify="space-around">
                      <b>Nome:</b>
                      <p>
                        {{
                          getOrderById(item.buyOrderId)
                            ? getOrderById(item.buyOrderId).name
                            : ""
                        }}
                      </p>
                    </v-row>
                    <v-row justify="space-around">
                      <b>Numero:</b>
                      <p>
                        {{
                          getOrderById(item.buyOrderId)
                            ? getOrderById(item.buyOrderId).buyOrder
                            : ""
                        }}
                      </p>
                    </v-row>
                  </v-card-text>
                </v-card>
              </td>
              <!-- <td>{{ item.itemId }}</td> -->
              <td>
                <OrdemItems
                  :item="getItemOrdemById(item.buyOrderId, item.itemId)"
                />
              </td>
              <td>{{ item.loaded ? "SIM" : "NÃO" }}</td>
              <td>{{ getUserNameById(item.userId) }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.description }}</td>
              <td>{{ getUserNameById(item.createUserId) }}</td>
              <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
              <td>
                <v-icon small @click="itemToSendDel(item)" class="m-1">
                  mdi-trash-can
                </v-icon>
                <v-icon small @click="itemToSendEdt(item)">
                  mdi-lead-pencil
                </v-icon>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <!-- DIALOG DE ENVIO -->
    <v-dialog v-model="toUserDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Enviar item para Usuário
        </v-card-title>

        <v-card-text>
          <v-row align="center">
            <v-col>
              <b class="text-h6">Codigo Fornecedor:</b>
            </v-col>
            <v-col>
              <p class="text-h6 mt-4">{{ toUserData.providerCode }}</p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center">
            <v-col>
              <b class="text-h6">Nome:</b>
            </v-col>
            <v-col>
              <p class="text-h6 mt-4">{{ toUserData.name }}</p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center">
            <v-col>
              <b class="text-h6">Quantidade:</b>
            </v-col>
            <v-col>
              <v-text-field
                label="Quantidade"
                v-model="toUserData.amount"
                hint="Quantidade do Item"
                persistent-hint
                type="number"
                :append-icon="toUserData.unity"
                min="0"
                :max="toUserData.amount"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center">
            <v-col>
              <b class="text-h6">Usuário:</b>
            </v-col>
            <v-col>
              <v-autocomplete
                class="mt-2"
                label="Usuário"
                v-model="toUserData.userId"
                :items="getUserSelect"
                hint="Usuário que faz coleta"
                persistent-hint
                @change="verifyTraceable"
              ></v-autocomplete>
              <!-- <v-checkbox
                v-if="userTraceable"
                v-model="traceUser"
                :label="`Rastrear pelo Aplicativo`"
                @change="setUniqueId"
              ></v-checkbox> -->
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" v-if="!userTraceable">
            <v-col>
              <b class="text-h6">Veículo:</b>
            </v-col>
            <v-col>
              <v-autocomplete
                class="mt-2"
                label="Veículo"
                v-model="toUserData.vehicleId"
                :items="getVehiclesSelect"
                hint="Veículo que fará a coleta"
                persistent-hint
                @change="setVehicle"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="center" v-else>
            <v-col>
              <b class="text-h6">Placa:</b>
            </v-col>
            <v-col>
              <v-col>
                <v-label>Rastreado pelo Celular</v-label>
              </v-col>
              <v-text-field
                label="Placa do Veículo"
                hint="Placa do Veículo que fará a coleta"
                persistent-hint
                v-model="toUserData.plate"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center">
            <v-col cols="4">
              <b class="text-h6">Descrição:</b>
            </v-col>
            <v-col>
              <v-textarea
                rows="2"
                label="Descrição / Observações"
                v-model="toUserData.description"
                hint="Alguma Instrução ou comentario para o usuário da coleta"
                persistent-hint
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="toUserDialog = false">
            Cancelar
          </v-btn>
          <v-btn color="primary" text @click="sendToUser"> Enviar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG DE ENVIO -->
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import OrdemItems from "../components/Ordem/Item";
export default {
  name: "ordemUsuario",
  components: {
    OrdemItems,
  },
  data() {
    return {
      showForm: false,
      ordem: {
        buyOrderItems: [],
      },
      toUserDialog: false,
      toUserData: {
        providerCode: "",
        name: "",
        description: "",
        plate: "",
      },
      userTraceable: false,
      traceUser: false,
      statusOptions: [
        { text: "Com saldo", value: "OPEN" },
        { text: "Sem saldo", value: "CLOSED" },
        { text: "Todas", value: "ALL" },
      ],
      filter: {
        constructionsId: [],
        status: "OPEN",
      },
      filterBU: {
        constructionsId: [],
        users: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      "getConstructions",
      "getOrdersFiltred",
      "getConstructionNameById",
      "getProviderNameById",
      "getConstructionsSelect",
      "getProvidersSelect",
      "getUserSelect",
      "getBOtoUsers",
      "getItemOrdemById",
      "getOrderById",
      "getUserNameById",
      "getVehiclesSelect",
      "getTraceableById",
      "getUserById",
      "getVehicleById",
      "getConstructionsSelect",
      "getOrdersBOFiltred",
      "getOrders",
    ]),
  },
  methods: {
    save() {
      this.$store.dispatch("saveOrder", this.ordem);
    },
    clearForm() {
      console.log("clearForm");
    },
    del(e) {
      this.$store.dispatch("delOrdem", e);
    },
    edt(e) {
      this.ordem = e;
      this.showForm = true;
    },
    addItem() {
      this.ordem.buyOrderItems.push({});
    },
    delItem(index) {
      this.ordem.buyOrderItems.splice(index, 1);
    },
    itemToSend(e) {
      // console.log(e);
      this.toUserDialog = true;
      this.toUserData = e;
    },
    itemToSendEdt(e) {
      let it = this.getItemOrdemById(e.buyOrderId, e.itemId);
      this.toUserDialog = true;
      this.toUserData = e;
      this.toUserData.providerCode = it.providerCode;
      this.toUserData.name = it.name;
    },
    itemToSendDel(e) {
      this.$store.dispatch("deletBOtoUser", e);
    },
    sendToUser() {
      // console.log(this.toUserData);
      this.$store.dispatch("saveBOtoUser", this.toUserData);
      this.toUserDialog = false;
    },
    verifyTraceable(item) {
      this.userTraceable = this.getTraceableById(item);
      let user = this.getUserById(this.toUserData.userId);
      if (user) {
        this.toUserData.uniqueId = user.uniqueId;
      }
      // console.log(this.getUserById(item));
    },
    setUniqueId(value) {
      if (value) {
        let user = this.getUserById(this.toUserData.userId);
        if (user) {
          this.toUserData.uniqueId = user.uniqueId;
        }
      }
    },
    setVehicle(value) {
      let vehicle = this.getVehicleById(value);
      if (vehicle) {
        this.toUserData.plate = vehicle.placa;
        this.toUserData.uniqueId = vehicle.uniqueid;
      }
    },
    getBOID(id, prop) {
      let bo = this.getOrderById(id);
      if (bo) {
        if (Object.hasOwnProperty.call(bo, prop)) {
          return bo[prop];
        } else {
          return "";
        }
      }
    },
    applyFilter() {
      this.$store.dispatch("setOrderFilter", this.filter);
    },
    // filtro para as ordens carregadas
    applyFilterBO() {
      // pegando os IDS das obras
      let bo = [];
      if (this.filterBU.constructionsId.length) {
        const filterModel = (arr = [], model = 0) =>
          arr.length ? arr.includes(model) : true;
        bo = this.getOrders.filter((order) =>
          filterModel(this.filterBU.constructionsId, order.constructionId)
        );
        bo = bo.map((item) => item.id);
      }
      // gerando o payload do filtro para o BOtoUser
      let payload = {
        users: this.filterBU.users,
        orders: bo,
      };
      this.$store.dispatch("setOrderFilterBO", payload);
    },
  },
  mounted() {
    this.$store.dispatch("loadOrders");
    this.$store.dispatch("loadProviders");
    this.$store.dispatch("loadUsers");
    this.$store.dispatch("loadBOtoUser");
    this.$store.dispatch("loadVehicles");
    this.$store.dispatch("loadConstructions");
  },
};
</script>

<style>
</style>