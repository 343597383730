<template>
  <v-navigation-drawer app dark v-model="drawer" mini-variant expand-on-hover >
    <!-- <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6"> Application </v-list-item-title>
        <v-list-item-subtitle> subtext </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item> -->

    <v-divider></v-divider>
    <v-list nav dense v-show="getLogged" class="over">
      <v-list-item
        link
        :to="menuItem.to"
        v-for="menuItem in getMenus"
        :key="menuItem.title"
      >
        <v-list-item-icon>
          <v-icon :color="menuItem.color">{{ menuItem.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data: () => {
    return {
      showMenu: false,
      menus: [],
    };
  },
  methods: {
    ...mapActions(["logOff", "setParameters"]),
    teste() {
      console.log("Teste");
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["getPermission", "getUsuario", "getLogged", "getDrawer","getMenus"]),
    drawer: {
      get() {
        return this.$store.getters.getDrawer;
      },
      set(value) {
        this.$store.dispatch("setDrawer", value);
      },
    },
  },
  watch: {
    user() {
      // let param = [];
      if (this.user.id) {
        // this.$http("nvmenu/user").then((resp) => {
        //   this.menus = resp.data;
        //   if (this.menus.length) {
        //     this.showMenu = true;
        //   }
        //   resp.data.forEach((element) => {
        //     param.push({ to: element.to, parameters: element.parameters });
        //   });
        //   this.setParameters(param);
        // });
        this.$store.dispatch("loadMenus");
      } else {
        this.menus = [];
        this.showMenu = false;
      }
    },
    getLogged() {
      if (this.getLogged) {
        if (this.menus.length) {
          this.showMenu = true;
        }
      } else {
        this.showMenu = false;
      }
    },
  },
};
</script>

<style scoped>
.over {
  overflow: auto;
  height: 99vh;
}
* {
  scrollbar-width: thin;
  scrollbar-color: gray rgb(82, 82, 82);
}
*::-webkit-scrollbar {
  width: 2px;
}
*::-webkit-scrollbar-track {
  background: rgb(161, 161, 161);
}
*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
  border: 3px solid rgb(82, 82, 82);
}
</style>