import axios from 'axios'
export default {
    state: {
        constructions: [],
        construction: {},
        edition: 0
    },
    mutations: {
        setConstructions(state, payload) {
            state.constructions = payload
        },
        setConstruction(state, payload) {
            state.construction = payload
        },
        setEdition(state, payload) {
            state.edition = payload
        },
    },
    actions: {
        setConstructions(context, payload) {
            context.commit('setConstructions', payload)
        },
        setConstruction(context, payload) {
            if (context.state.construction.name == payload.name) {
                context.commit('setConstruction', {})
            } else {
                context.commit('setConstruction', payload)
            }
        },
        setEdition(context, payload) {
            context.commit('setEdition', payload)
        },
        loadConstructions(context) {
            axios(`obras`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                    // element.coordinates = JSON.parse(element.coordinates)
                });
                context.commit('setConstructions', resp.data)
            });
        },
        loadAllConstructions(context) {
            axios(`obras/all`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                    // element.coordinates = JSON.parse(element.coordinates)
                });
                context.commit('setConstructions', resp.data)
            });
        },
        saveConstruction(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            // payload.coordinates = JSON.stringify(payload.coordinates)
            axios[metodo](`/obras${finalUrl}`, payload).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Obra salva com Sucesso");
                    context.dispatch('loadConstructions')
                }
            });
        },
        deletConstruction(context, payload) {
            axios.delete(`/obras/${payload.id}`).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Obra Deletada com Sucesso");
                    context.dispatch('loadConstructions')
                }
            });
        },
    },
    getters: {
        getConstructions(state) {
            return state.constructions
        },
        getConstruction(state) {
            return state.construction
        },
        getEdition(state) {
            return state.edition
        },
        getConstructionsSelect(state) {
            let sel = []
            state.constructions.forEach(e =>{
                sel.push({value: e.id, text:`${e.id} - ${e.name}`})
            })
            return sel
        },
        getConstructionNameById: (state) => (id) => {
            if (!state.constructions) {
                return id
            }
            let obj = state.constructions.find(g =>
                g.id == id
            )
            return obj ? `${obj.id} - ${obj.name}` : ''
        },
        getConstructionById: (state) => (id) => {
            if (!state.constructions) {
                return id
            }
            let obj = state.constructions.find(g =>
                g.id == id
            )
            return obj
        },
        getConstructionHeaders() {
            return [
                { text: "ID", value: "id" },
                { text: "Nome", value: "name" },
                { text: "Construction Pai", value: "groupId" },
                { text: "Construction Pai Nome", value: "groupName" },
                { text: "Descrição", value: "description" },
                { text: "Ativo", value: "ativo" },
                { text: "Ações", value: "actions", sortable: false },
            ]
        }
    },
}