import axios from 'axios'
export default {
    state: {
        detached: [],
    },
    mutations: {
        setDetached(state, payload) {
            state.detached = payload
        },
    },
    actions: {
        setDetached(context, payload) {
            context.commit('setDetached', payload)
        },
        loadDetached(context) {
            axios(`avulso`).then((resp) => {
                context.commit('setDetached', resp.data);
            });
        },
        saveDetached(context, payload) {
            // const metodo = payload.id ? "put" : "post";
            // const finalUrl = payload.id ? `/${payload.id}` : "";
            axios.post(`/avulso`, payload).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "avulso salva com Sucesso");
                    context.dispatch('loadDetached');
                    if(typeof payload.callback === 'function') {
                        payload.callback()
                    }
                }
            });
        },
        deletDetached(context, payload) {
            axios.delete(`/avulso/${payload.id}`).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "avulso Deletada com Sucesso");
                    context.dispatch('loadDetached')
                }
            });
        },

    },
    getters: {
        getDetached(state) {
            return state.detached
        },
        getDetachedById: (state) => (id) => {
            if (!state.detached) {
                return id
            }
            let finded = state.detached.find(g =>
                g.id == id
            )
            return finded ? `${finded.id} - ${finded.name}` : ''
        },
    }
}