<template>
  <v-card>
    <v-card-title> Veículos </v-card-title>
    <v-card-text>
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">ID</th>
            <th class="text-left">Identificação</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in getVehicles" :key="index">
            <td>{{ item.value }}</td>
            <td>{{ item.text }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "veiculos",
  computed: {
    ...mapGetters(["getVehicles"]),
  },
  data: () => ({}),
};
</script>

<style>
</style>